import axios from "axios";

export default {
	getData(query, isMobile) {
		const response = axios.get("/SearchCompare/GetFilterPanel?" + query, {
			params: { mobile: isMobile },
		});
		return response;
	},
	getAdmissionStatistics(query) {
		const response = axios.get(
			"/SearchCompare/GetAdmissionStatistics?" + query
		);
		return response;
	},
	getLabourForecastJson(lf, d, errorFunction) {
		var query = "lf=" + lf + "&d=" + d;
		const response = axios
			.get("/SearchCompare/GetLabourForecastJson?" + query)
			.catch(errorFunction);
		return response;
	},
	getLabourForcasts(lf, d) {
		var query = "lf=" + lf + "&d=" + d;
		const response = axios.get("/SearchCompare/GetLabourForecast?" + query);
		return response;
	},
	postData(query, selectedOption) {
		const response = axios.post(
			"/SearchCompare/GetResultsJson?" + query,
			{},
			{
				params: {
					so: selectedOption,
				},
			}
		);
		return response;
	},
	getEducationsComparison(educations) {
		const res = axios.post("/SearchCompare/GetEducationsComparison", {
			educations,
		});
		return res;
	},
	getAdmissionStatisticsJSON(query) {
		const queryString = query.split("?")[1];
		const response = axios.get(
			"/SearchCompare/GetAdmissionStatisticsJson?" + queryString
		);
		return response;
	},
	getSuggestions(query) {
		const response = axios.post(
			"/SearchPage/Autocomplete",
			{},
			{
				params: {
					query: query,
				},
			}
		);
		return response;
	},
};
