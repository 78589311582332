<template>
    <div class="compare-filter-button" :class="{ checked: checked }" role="group" v-click-outside="handleClickOutside">
        <div class="checkbox-container">
            <checkbox :filter="filter" :index="index" @filterselected="toggle">
            </checkbox>
        </div>
        <infoPopup v-if="this.modalVisible && !this.isMobile" @closeModal="closeModal()"
            infoText="Du kan jämföra max två utbildningar i taget. För att kunna jämföra den här utbildningen behöver du klicka ur rutan vid en utbildning som du redan har valt. "
            buttonText="Okej"></infoPopup>
    </div>
    <infoPopup v-if="this.modalVisible && this.isMobile" @closeModal="closeModal()"
        infoText="Du kan jämföra max två utbildningar i taget. För att kunna jämföra den här utbildningen behöver du klicka ur rutan vid en utbildning som du redan har valt. "
        buttonText="Okej"></infoPopup>
</template>

<script>
import SvgIcon from "../../misc-components/svgicon.vue";
import checkbox from "../../components/checkbox.vue";
import infoPopup from "./infoPopup.vue"
import clickOutside from '../../directives/vclickoutside';
import { ref, nextTick } from "vue";

export default {
    name: "filterButton",
    components: {
        SvgIcon,
        checkbox,
        infoPopup,
    },
    directives: {
        clickOutside,
    },
    emits: ['filterselected', 'close', 'closeModal'],
    props: {
        item: Object,
        index: Number,
        isChecked: Boolean,
        maxItemsChecked: Boolean,
        isMobile: Boolean
    },
    setup(props) {
        const createHref = (events) => {
            return events.map(event => event.Id).join(',');
        };

        const checked = ref(props.isChecked);

        const filter = ref({
            Title: props.item.Title + ", " + props.item.Provider,
            FilterItemInfo: null,
            IsSelectedFilter: props.isChecked,
            Href: createHref(props.item.Events),
            ShowAll: "",
            disabled: false
        });

        const modalVisible = ref(false);
        return {
            filter,
            createHref,
            checked,
            modalVisible
        }
    },
    watch: {
        isChecked: {
            immediate: true,
            handler(newVal) {
                this.filter.IsSelectedFilter = newVal;
                this.checked = newVal;
            }
        }
    },
    methods: {
        async toggle() {
            if (!this.checked && this.maxItemsChecked) {
                this.filter.IsSelectedFilter = true;
                this.modalVisible = !this.modalVisible;
                await nextTick(); // Wait for the DOM to update

                this.filter.IsSelectedFilter = false;
            } else {
                this.checked = !this.checked;
            }
            this.$emit("filterselected", this.item.Id, this.checked);
        },
        async handleClickOutside() {
            await nextTick();
            this.modalVisible = false;
        },
        handleClose(id, href) {
            this.$emit('close', id, href);
        },
        closeModal() {
            this.modalVisible = false;
        }
    }
};
</script>