<template>

    <div v-if="this.dataLoaded === false" class="edu-admission-stats-spinner">
        <img src="/assets/img/uhr-spinner.gif" alt="Loading..." />
    </div>
    <div v-else-if="this.admissionStatistics && this.admissionStatistics.AdmissionStatistics.length > 0"
        class="edu-admission-stats">
        <button type="button" data-toggle="collapse" :data-target="`#${Id}-anstat-collapse`" aria-expanded="false"
            :aria-controls="`${Id}-anstat-collapse`" @click="toggleAdmissionStats()"
            :class="`admission-stats-button ${setPlausibleTracking()}`">
            <SvgIcon :IconName="isOpen ? 'minus' : 'plus'" Title=true TitleText=""></SvgIcon>

            <div>
                <span>{{ expandButtonText }}</span>
                <span class="button-no-wrap">{{ provider }}</span>
            </div>

            <SvgIcon v-if="isMobile" :IconName="isOpen ? 'minus' : 'plus'" Title=true TitleText=""></SvgIcon>
        </button>
    </div>
    <div v-else class="edu-admission-nostat">
        <div v-if="statisticsNotAvailableText" class="no-stats-heading">
            <span>{{ statisticsNotAvailableText }}</span>
            <span class="button-no-wrap">{{ provider }}</span>
        </div>

        <div class="selection-group-info no-show">
            <SvgIcon IconName="information"></SvgIcon>
            <span>
                Vi kan inte visa antagningsstatistik för denna utbildning.
            </span>
        </div>
    </div>
    <div v-if="this.isOpen" class="edu-admission-info collapse" :id="`${Id}-anstat-collapse`">
        <h4 class="sr-only">Antagningsstatistik</h4>
        <AdmissionStatsInfo v-if="showInfo" heading="Det här visar antagningsstatistiken" :isOpen="isOpen"
            :providedAboutTable="providedAboutTable"
            :providedMoreAboutAdmissionStatistics="providedMoreAboutAdmissionStatistics">
        </AdmissionStatsInfo>
        <div class="select-container row">
            <h4 class="col-xs-12">Vill du filtrera din sökning?</h4>
            <div class="col-xs-12 col-sm-6 filter-group">
                <fieldset>
                    <legend> Välj max {{ selectiongroupcountbreakpoint }} urvalsgrupper </legend>
                    <MultiSelectDropDown :propsList="finalArray.headerlist" v-model="selectedValues"
                        :maxActiveItems="selectiongroupcountbreakpoint" :id="`${Id}-multiselect`"
                        :plausibleTrackingClass="'plausible-event-name=HOJ+antstat+urvalsgrupp'">
                    </MultiSelectDropDown>
                </fieldset>

                <!-- Nedan är dold tillsvidare (CMS-1267). Om den i framtiden plockas bort, rensa även bort css! -->
                <!-- <fieldset v-if="finalArray.NumberOfTables.length > 1" class="multiple-checkbox">
                    <legend>2. Välj utbildningstillfälle</legend>
                    <div class="multiple-course">
                        <label :for="course.ApplicationCode + '-' + course.Pace + '-' + index" tabindex="-1"
                            :class="`container-choice ${isSelected(index) ? 'active' : ''}`"
                            v-for="(course, index) in finalArray.NumberOfTables">
                            <input type="checkbox" :checked="isSelected(index)"
                                :id="course.ApplicationCode + '-' + course.Pace + '-' + index" :value="index"
                                v-model="selectedTables" />
                            <span class="txt">{{ course.Pace }}%, {{ course.Distance === "true" ? "Distans" : "På plats"
                                }},
                                {{ course.City }}</span>
                        </label>
                    </div>
                </fieldset> -->
                <!-- Ovan är dold tillsvidare (CMS-1267). Om den i framtiden plockas bort, rensa även bort css! -->

                <div class="filter-button-group">
                    <button class="btn btn-primary" @click="showSelectedValues">Visa</button>
                    <button class="btn btn-secondary" v-if="showResetButton()" @click="resetSelectedValues">
                        Rensa Filter</button>
                </div>
            </div>
        </div>
        <div v-if="showInfoSelectionGroupCount" class="selection-group-info">
            <SvgIcon IconName="information"></SvgIcon>
            <span>
                Ser du inte din urvalsgrupp i tabellen? Alla urvalsgrupper finns i filtreringen
            </span>
        </div>
        <div v-for="(course, tableIndex) in finalArray.body" class="antstat-tables">
            <div v-if="setVisibleTable(tableIndex)" class="anstat-table">
                <h5 class="info-title">
                    {{ resultInfo.Title }}
                </h5>
                <div class="info-wrapper">
                    <span class="info-text school">
                        <SvgIcon IconName="geografisk-knappnal" Title=true TitleText="Universitet/högskola"></SvgIcon>{{
        resultInfo.Provider }}<span class="event-text"></span>
                    </span>
                    <span class="info-text HP">
                        <SvgIcon Title=true TitleText="Omfattning" IconName="klocka"></SvgIcon>{{
        removeZeroButNotFive(resultInfo.Hp) }} hp {{ resultInfo.TimeParsed }}
                    </span>
                    <span class="info-text time">
                        <SvgIcon Title=true TitleText="Studietakt"
                            :IconName="studyPaceToIcon(finalArray.NumberOfTables[tableIndex].Pace, false)">
                        </SvgIcon>{{
        finalArray.NumberOfTables[tableIndex].Pace }}%
                    </span>
                    <span class="info-text course">
                        <SvgIcon Title=true TitleText="Utbildningsnivå" IconName="examenshatt"></SvgIcon>{{
        courseOrProgram }}
                    </span>
                    <span v-if="finalArray.NumberOfTables[tableIndex].Distance"
                        :class="`info-text ${finalArray.NumberOfTables[tableIndex].Distance === 'true' ? 'distance-background' : ''}`">{{
        finalArray.NumberOfTables[tableIndex].Distance === "true" ? "Distans" : "På plats" }}</span>
                    <span v-if="resultInfo.Events.length > 1" class="info-text city">
                        {{ finalArray.NumberOfTables[tableIndex].City }}
                    </span>
                </div>
                <div class="info-wrapper-admin" v-if="IsVisibleForAdmin">
                    <p>{{ resultInfo.Id }}</p>
                    <p>{{ resultInfo.Specialization }}</p>
                </div>

                <div class="display-admission-stats">
                    <table class="stat-table">
                        <tbody>
                            <tr>
                                <th class="header-width">Termin</th>
                                <th v-for="(urvalsgrupp, index) in finalArray.headerlist.slice(0, 9)"
                                    :key="urvalsgrupp.Code" :class="setVisiableValues(index)">
                                    <div class="th-container">
                                        <span aria-hidden="true">
                                            {{ urvalsgrupp.Code }}
                                        </span>
                                        <span v-if="urvalsgrupp.Explanation != this.missinginfotext" class="sr-only">
                                            Urvalsgrupp: {{ urvalsgrupp.Explanation }}
                                        </span>
                                        <span v-else class="sr-only">
                                            Urvalsgrupp: {{ urvalsgrupp.Code }}
                                        </span>
                                        <div class="info-icon-container">
                                            <Informationicon :filterItemInfo="urvalsgrupp.Explanation"
                                                :filterTitle="urvalsgrupp.Code" filterGroup="" />
                                        </div>
                                    </div>
                                </th>
                            </tr>

                            <tr v-for="item in course" :key="item.id">
                                <th class="header-width" scope="row" :aria-label="setSemesterLabel(item.Semester)">{{
        item.Semester }}</th>
                                <td v-for="(value, index) in item.SelCriterion.slice(0, 9)" :key="value.Code"
                                    @mouseover="highlight(index, tableIndex)"
                                    @mouseleave="unhighlight(index, tableIndex)"
                                    :class="[{ highlighted: activeTableIndex === tableIndex && highlightedCells === index }, setVisiableValues(index)]">
                                    {{ value.Value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-html="providedTableSymbols"></div>

        <UnderLineAccordion v-if="isOpen" :heading="'Vilken urvalsgrupp tillhör jag?'"
            :text="providedWhatSelectionGroups" :isHtml="true"></UnderLineAccordion>
        <UnderLineAccordion v-if="isOpen" :heading="'Om urvalsgrupperna'" :text="providedAboutSelectionGroups"
            :isHtml="true">
        </UnderLineAccordion>

    </div>
    <InformationPopup />
</template>


<script>
import SvgIcon from "../misc-components/svgicon.vue";
import AdmissionStatsInfo from "../components/admission-stats-info.vue";
import MultiSelectDropDown from "../components/mulitselect-dropdown.vue";
import UnderLineAccordion from "../components/underline-accordion.vue";
import { urvalsgrupper } from "../constants";
import InformationPopup from "./informationpopup.vue";
import Informationicon from "./informationicon.vue";
import api from "../plugins/searchcompareservice";

export default {
    name: "AdmissionStatsAccordion",
    components: {
        SvgIcon,
        MultiSelectDropDown,
        InformationPopup,
        Informationicon,
        AdmissionStatsInfo,
        UnderLineAccordion
    },
    emits: ["dataFetched"],
    props: {
        Id: Number,
        resultInfo: Object,
        admissionStatisticsUrl: {
            type: String,
            required: true
        },
        IsVisibleForAdmin: Boolean,
        isMobile: Boolean,
        courseOrProgram: String,
        buttonText: String,
        provider: String,
        isLoading: Boolean,
        showInfo: Boolean
    },
    inject: ['providedAboutTable', 'providedTableSymbols', 'providedWhatSelectionGroups', 'providedAboutSelectionGroups', 'providedMoreAboutAdmissionStatistics'],
    data() {
        return {
            isOpen: false,
            urvalsgrupper: urvalsgrupper,
            selectedValues: [],
            selectedUg: [],
            highlightedCells: [],
            selectedTables: [],
            selectedTable: [],
            activeTableIndex: null,
            missinginfotext: "Högskolan har information om urvalsgruppen",
            selectiongroupcountbreakpoint: null,
            admissionStatistics: null,
            hasAdmissionData: false,
            dataLoaded: false
        }
    },
    watch: {
        admissionStatisticsUrl: 'fetchAdmissionStatistics'
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.fetchAdmissionStatistics();
    },
    beforeDestroy() {
        // Remove the resize event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        async fetchAdmissionStatistics() {
            if (this.admissionStatisticsUrl) {
                await api.getAdmissionStatisticsJSON(this.admissionStatisticsUrl)
                    .then((result) => {
                        this.admissionStatistics = result.data.AdmissionStatisticsObject;
                        this.hasAdmissionData = this.admissionStatistics && this.admissionStatistics.AdmissionStatistics.length > 0;
                        if (this.isLoading == true) {
                            this.$emit('dataFetched', this.Id, this.hasAdmissionData);
                        }
                        this.dataLoaded = true;

                    })
                    .catch((err) => {
                        console.log(err);
                        this.hasAdmissionData = false;
                        this.$emit('dataFetched', this.Id, false);
                    });
            }
        },
        handleResize() {
            this.setSelectionGroupCountBreakpoint();
        },
        setSelectionGroupCountBreakpoint() {
            if (window.innerWidth <= 767) {
                this.selectiongroupcountbreakpoint = 2;
            } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                this.selectiongroupcountbreakpoint = 7;
            }
            else {
                this.selectiongroupcountbreakpoint = 9;
            }
        },
        toggleAdmissionStats() {
            this.isOpen = !this.isOpen;
        },
        highlight(cellId, tableIndex) {
            this.highlightedCells = cellId;
            this.activeTableIndex = tableIndex;
        },
        unhighlight(cellIndex, tableIndex) {
            if (this.activeTableIndex === tableIndex && this.highlightedCells === cellIndex) {
                this.highlightedCells = null;
                this.activeTableIndex = null;
            }
        },
        getUniqueCodes(data) {
            const uniqueCodes = new Set();
            data.forEach(item => {
                item.SelCriterion.forEach(criterion => {
                    uniqueCodes.add(criterion.Code);
                });
            });
            return uniqueCodes;
        },
        setPlausibleTracking() {
            //Gör tvärtom eftersom knappen hinner ändra klass innan den registeraras i plausible
            return this.isOpen ? 'plausible-event-name=HOJ+antstat+open' : "";
        },
        setSemesterLabel(semester) {
            var term = "";
            if (semester.includes("HT")) {
                term = "Hösttermin "
            }
            else {
                term = "Vårtermin "
            }
            return term + semester.slice(2, 6);

        },
        showSelectedValues() {
            this.selectedUg = this.selectedValues;
            this.selectedTable = this.selectedTables;
        },
        showResetButton() {
            if (this.selectedValues.length > 0 || this.selectedTables.length > 0) {
                return true;
            }
            return false;
        },
        resetSelectedValues() {
            this.selectedValues = [];
            this.selectedTables = [];
            this.showSelectedValues();
        },
        setVisiableValues(index) {
            if (index >= 2 && index <= 6) {
                return "hidden-xs"
            } else if (index > 6) {
                return "hidden-xs hidden-sm"
            }
        },
        isSelected(code) {
            return this.selectedTables.includes(code);
        },
        setVisibleTable(index) {
            if (this.selectedTable.length > 0 && !this.selectedTable.includes(index)) {
                return false;
            }
            return true;
        },

        processBody(coursesArray, numberOfTables, codesMap) {
            const body = numberOfTables.map(session => {
                return coursesArray.filter(course =>
                    course.Distance === session.Distance &&
                    course.City === session.City &&
                    course.Pace === session.Pace);
            });

            const result = body.map(group => group.map(entry => {
                const transformedEntry = { ...entry };
                transformedEntry.SelCriterion = codesMap.map(code => {
                    const criterion = entry.SelCriterion.find(c => c.Code === code.Code);
                    return {
                        Code: code.Code,
                        Value: criterion ? criterion.Value : 'X'
                    };
                });
                return transformedEntry;
            }));

            return result;
        },
        processHeaderArray(data, codes, selectedUg) {
            if (selectedUg && selectedUg.length > 0) {
                return Array.from(selectedUg).map(code => ({
                    Code: code,
                    Explanation: codes.get(code) || this.missinginfotext
                }));
            } else {
                const uniqueCodes = new Set(data.flatMap(item => item.SelCriterion.map(c => c.Code)));
                let formattedArray = [];

                for (let [code, explanation] of codes) {
                    if (uniqueCodes.has(code)) {
                        formattedArray.push({ Code: code, Explanation: explanation });
                        uniqueCodes.delete(code);
                    }
                }

                uniqueCodes.forEach(code => {
                    formattedArray.push({
                        Code: code,
                        Explanation: this.missinginfotext
                    });
                })

                return formattedArray;
            }
        },
        generateCourseSessions(data) {
            const uniqueSessions = data.reduce((acc, item) => {
                const key = `${item.Distance}-${item.City}-${item.Pace}`;
                if (!acc.has(key)) {
                    acc.set(key, {
                        ApplicationCode: item.ApplicationCode,
                        Distance: item.Distance,
                        City: item.City,
                        Pace: item.Pace
                    });
                }
                return acc;
            }, new Map());

            return Array.from(uniqueSessions.values());
        },
        createFinalArray(firstArray, secondArray) {
            const codesMap = new Map(secondArray.map(code => [code.code, code.explanation]));
            const headerList = this.processHeaderArray(firstArray, codesMap, this.selectedUg);
            const numberOfTables = this.generateCourseSessions(firstArray);
            const processedContent = this.processBody(firstArray, numberOfTables, headerList, this.selectedUg);

            return {
                headerlist: headerList,
                NumberOfTables: numberOfTables,
                body: processedContent
            };
        }
    },
    computed: {
        finalArray() {
            return this.createFinalArray(this.admissionStatistics.AdmissionStatistics, this.urvalsgrupper);
        },
        showInfoSelectionGroupCount() {
            this.setSelectionGroupCountBreakpoint();
            return this.finalArray && this.finalArray.headerlist.length > this.selectiongroupcountbreakpoint;
        },
        expandButtonText() {
            if (this.buttonText) {
                return this.buttonText;
            } else {
                return "Antagningsstatistik";
            }
        },
        statisticsNotAvailableText() {
            if (this.buttonText) {
                return this.buttonText;
            } else {
                return null;
            }
        }
    }
};
</script>