import { reactive, computed } from "vue";
import { Education, Event, CompareItem, EducationStart } from "./models";
import searchcompareservice from "js/vue/plugins/searchcompareservice";

const eduCompareData = reactive({
	compareItems: [] as Array<any>, // antal utbildningar
	maxNumberofItems: 5,
	returnUrl: "",
	showMore: false,

	text: {
		ShowMore: "Se mer om utbildningarna",
		ShowLess: "Se mindre om utbildningarna",
		DegreeNA: "Nej",
		NA: "–", // halvfyrkantsminus
		EventLabel: "Tillfälle",
		NoData:
			"Du behöver välja minst en utbildning för att kunna se information och minst 2 för att jämföra dem.",
	},

	init(data: Education[], _returnUrl: string) {
		this.returnUrl = _returnUrl;
		this.compareItems = new Array<CompareItem>();

		const urlSearchParams = new URLSearchParams(this.returnUrl);
		const ceParam = urlSearchParams.get("ce");

		data.forEach((element: Education, index: number) => {
			element.Events.sort((a, b) => {
				let eventStartA = new EducationStart(a.Start);
				let eventStartB = new EducationStart(b.Start);
				return eventStartA.compare(eventStartB); // sortera i stigande ordning
			});

			// Examen => nej, om det inte finns
			element.Events.forEach((event) => {
				if (
					event.Degree === undefined ||
					event.Degree === null ||
					event.Degree === ""
				) {
					event.Degree = this.text.DegreeNA;
				}
			});

			this.compareItems.push({
				data: element,
				name: element.Title,
				id: element.Id,
				visible: true,
				checked: index < this.maxNumberofItems,
				urlToStatistics: "https://stat.com",
				urlToLabourForecast:
					element.LabourForcastUrl +
					"&ce=" +
					encodeURIComponent(ceParam ?? "") +
					"&cv=",
			});
		});
	},

	getEducationById(id: number) {
		return this.compareItems.find((edu: { id: number }) => edu.id == id);
	},
});

export function eduCompData() {
	const ensureLoaded = (options: any) => {
		searchcompareservice
			.getEducationsComparison(options.ce)
			.then((result: any) => {
				if (result && result.status === 200) {
					eduCompareData.init(result.data, options.returnUrl);
				}
			})
			.catch((err: Error) => {
				console.log(err);
			});
	};

	return {
		eduCompareData: eduCompareData,
		ensureLoaded: ensureLoaded,
		compareItems: computed(() =>
			eduCompareData.compareItems
				.filter((item) => item.visible && item.checked)
				.filter((_v, i) => i < eduCompareData.maxNumberofItems)
		),
	};
}

// https://www.studera.nu/jamfor-utbildning/?q=&ce=e.uoh.uu.2is225.26061.20242%2ce.uoh.uu.2is225.26032.20242%2ce.uoh.uu.2is225.26032.20232%2ce.uoh.uu.2is225.26061.20232%2ce.uoh.uu.5rg414.09542.20251%2ce.uoh.hj.lgrg14.12887.20242%2ce.uoh.lu.ctrd05.s0800.20241%2ce.uoh.lu.arkd06.30003.20242&cv=1
