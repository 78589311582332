export default function collapsibleheaders() {
  if ($("#HiddenFieldGroupContentH2").val() == 'True') {
    $(".articlePage").togglepanels('h2');
  }
  if ($("#HiddenFieldGroupContentH3").val() == 'True') {
    $(".articlePage").togglepanels('h3');
  }
}

$.fn.togglepanels = function (tagtype) {
  if (!isValidTagName(tagtype)) return;
  return this.each(function () {
    //Add classes to seletor-element.
    $(this).addClass("ui-accordion ui-accordion-icons ui-widget ui-helper-reset")
    //Find every element of type (heading) and wrap content and add events.
    $(this).find(tagtype).each(function (index, item) {
      var $headertoRepace = $(this);
      if ($headertoRepace.parents('#blockarealeft, #blockareamiddle').length > 0) {
        //don't apply header toggling on pufflbocks
        return;
      }

      var contentId = `content-${tagtype}-${index}`;
      $headertoRepace.nextUntil(wrapperselector(tagtype)).wrapAll(`<div class="content" id="${contentId}" />`);
      $headertoRepace.replaceWith(`<${tagtype} id="foldable${tagtype + index}" class="state-default"><button aria-expanded="false" aria-controls="${contentId}">${$(this).text()}<span class="caret"></span></button></${tagtype}>`);
      var $currentHeader = $(`#foldable${tagtype + index}`)
      var currentHeaderId = $currentHeader.attr("id").replace("foldable", "");
      //attach event to new header-element
      $currentHeader
        .on('mouseover', (e) => {
          $currentHeader.addClass("state-hover");
        })
        .on('mouseout', (e) => {
          $currentHeader.removeClass("state-hover");
        })
        .on('click', (e) => {
          togglePanelClick($currentHeader);
          var isOpen = $currentHeader.hasClass("open");
          $currentHeader.find("button").attr("aria-expanded", isOpen);
          updateHash(isOpen, currentHeaderId);
          e.preventDefault();
        })
        //Go next sbiling and hide the newly wrapped content
        .next()
        .hide();
      if (openByHash(currentHeaderId)) {
        //Auto open header, if id is present in hash.
        togglePanelClick($currentHeader, false);
      }
    });
  });
};

function isValidTagName(tagtype) {
  //Validate only h2 or h3 elements
  if (!tagtype) {
    console.warn('Warning, Empty tagtype received')
    return false;
  }
  tagtype = tagtype.toLowerCase()
  if (tagtype == 'h2') return true;
  if (tagtype == 'h3') return true;
  console.warn('Warning, Invalid tagtype received')
  return false
}

function wrapperselector(tagtype) {
  //Special selector for h3-elements. to make sure we're not wrapping h2-elements in our h3 wrapper. (or div's contaning h2)
  if (tagtype == 'h3')
    return 'h2, h3, div:has(h2)';
  return tagtype;

}
function togglePanelClick($currentHeader, shouldSlideOpen = true) {
  $currentHeader.toggleClass("state-active open")
    .find("> .caret").toggleClass("rotate").end()
    .next().slideToggle(shouldSlideOpen ? 500 : 0);
}
function openByHash(id) {
  id = hashifyId(id);
  if (window.location.hash.indexOf(id) >= 0) {
    return true
  }
  return false
}

//Updates window.location.hash with id. 
function updateHash(isOpen, id) {
  id = hashifyId(id);
  try {
    var currentHash = window.location.hash,
      updatedHash = '';
    if (isOpen) {
      if (currentHash.indexOf(id) < 0) {
        //id is missing, adding ti hash
        if (!currentHash.indexOf("#") > 0) {
          updatedHash = currentHash + id;
        } else {
          updatedHash = "#" + id;
        }
      }
    } else {
      //remove current id from Hash
        updatedHash = currentHash.replace(id, "");
        if (updatedHash === "#") {
          removeHashFromUrl();
        }
    }
    window.location.hash = updatedHash;
  } catch (e) {
    console.log(e.message);
  }
}
function hashifyId(id) {
  if (id.charAt(0) != ';') {
    return ';' + id;
  }
  return id;
}
function removeHashFromUrl() { 
  history.pushState("", document.title, window.location.pathname + window.location.search);
}