<template>
  <button :class="['miscfilters', isButtonOpen ? 'open' : 'closed']"
    :aria-expanded="`${isButtonOpen ? 'true' : 'false'}`" @click="handleClick" @blur="isOpen = false">
    {{ title }}<SvgIcon IconName="triangel-pil-ned"></SvgIcon>
  </button>
  <div id="filterMenu" v-if="isButtonOpen && filterList">
    <div class="subfilter multiple" v-if="m === 1">
      <div class="subfilterlayout1">
        <div v-for="(filter, index) in filterList.SelectedFilterResult" :key="filter.Title"
          :class="`primary ${borderColor(index)}`">
          <button @click="toggleSubFilter(index, filter.SubFilterItems, filter.HrefPrimary, filter.Value)"
            :id="`subfilterbutton${index}`" :value="filter.HrefPrimary"
            :class="`${activeSubMenu === index ? 'open' : 'close'}`"
            :aria-expanded="`${activeSubMenu === index ? 'true' : 'false'}`">
            <span>{{ filter.Title }}</span>
            <SvgIcon IconName="triangel-pil-hoger"></SvgIcon>
          </button>
          <div class="subfiltergrid">
            <div class="subfiltergriditems container" v-if="index === activeSubMenu">
              <div class="row">
                <div class="col-sm-12">
                  <checkBox :filter="filter" :index="0" :selectedFilter="0" @filterselected="toggle"
                    :plausibleTrackingClass="'plausible-event-name=HOJ+filter+' + title">
                  </checkBox>
                </div>
              </div>
              <div class="row">
                <div v-for="(sublist, sublistindex) in sublists" :key="sublistindex" class="col-sm-4">
                  <ul class="sublists">
                    <li v-for="(subfilter, subindex) in sublist" :key="subindex + sublistindex"
                      v-bind:id="filter.Value">
                      <checkBox :filter="subfilter" :index="subindex" :selectedFilter="subindex + 1"
                        @filterselected="toggle" :plausibleTrackingClass="'plausible-event-name=HOJ+filter+' + title">
                      </checkBox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subfilter" v-else>
      <div class="subfilterlayout2">
        <span class="subfilterlayout2-text">
          {{ title }}
        </span>
        <informationIcon class="header" :filterItemInfo="filterList.title" :filterGroup="title" filterTitle="" />
        <div class="subfiltergriditems container">
          <div class="row">
            <div v-for="(list, listIndex) in  filterList " :key="listIndex" class="col-sm-3">
              <ul>
                <li v-for="(filter, index) in  list " :key="index + listIndex" v-bind:id="filter.Value">
                  <checkBox :filter="filter" :index="index" :selectedFilter="(isNaN(listIndex) ? 0 : listIndex) + 1"
                    @filterselected="toggle" :plausibleTrackingClass="'plausible-event-name=HOJ+filter+' + title">
                  </checkBox>
                </li>
              </ul>
            </div>
            <div class="col-sm-3">
              <ul v-if="extraFilter" class="extrafilter-content">
                <li v-for="( item, index ) in  extraFilter.SelectedFilterResult ">
                  <checkBox :filter="item" :index="index" :selectedFilter="5" @filterselected="toggle"
                    :plausibleTrackingClass="'plausible-event-name=HOJ+filter+ejtidigare'">
                  </checkBox>
                </li>
              </ul>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "../misc-components/svgicon.vue";
import checkBox from "./checkbox.vue";
import informationIcon from "../components/informationicon.vue";
export default {
  name: "filterelement",
  components: {
    SvgIcon,
    checkBox,
    informationIcon
  },
  emits: ["filterElement", "filterselected", "updateSubFilter"],
  props: {
    title: String,
    m: Number,
    query: String,
    isMobile: Boolean,
    activeButtonId: Number,
    setActiveSubMenuIndex: Number,
    index: Number,
    filterList: Object,
    sublists: Array,
    extraFilter: Object
  },
  data() {
    return {
      isOpen: false,
      lists: [],
      activeSubFilterId: null
    };
  },
  methods: {
    handleClick() {
      this.$emit("filterElement", this.m);
    },
    toggle(data) {
      this.$emit("filterselected", data);
    },
    toggleSubFilter(index, subList, href, value) {
      this.activeSubFilterId = index === this.activeSubFilterId ? null : index;
      this.$emit("updateSubFilter", index, subList, href, value);
    },
    borderColor(index) {
      if (this.activeSubFilterId !== null && this.activeSubFilterId !== index) {
        return "non-active";
      } else if (this.activeSubFilterId == null && this.activeSubFilterId !== index) {
        return ""
      } else {
        return "active";
      }
    },
  },
  computed: {
    isButtonOpen() {
      this.isOpen = this.activeButtonId == this.m
      return (this.isOpen);
    },
    activeSubMenu() {
      this.activeSubFilterId = this.setActiveSubMenuIndex;
      return this.activeSubFilterId;
    }
  },
};
</script>