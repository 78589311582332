<template>
    <div id="CompareBasketWrapper"
        v-if="searchResult.EducationsCompareBasket && searchResult.EducationsCompareBasket.length > 0">
        <div id="CompareBasket" class="row" role="log">
            <div class="sr-only">{{ searchResult.CompareChoosenForComparison }}
            </div>
            <div class="col-md-2 col-md-pull-10 first-in-basket">
                <h2 class="basket-header hidden-xs hidden-sm">
                    {{ searchResult.CompareChoosenForComparison }}</h2>
                <h2 class="basket-header basket-count visible-xs visible-sm"> {{
            searchResult.EducationsCompareBasket.length
        }}
                    {{ searchResult.CompareEducationChoosen }}</h2>
                <button @click.prevent="showHideSelected" id="showCompareBasket" class="visible-xs visible-sm"
                    :href="searchResult.ShowCompareBasketUrl">
                    {{ searchResult.ShowHideSelected }}
                </button>
                <a id="toCompareView" class="plausible-event-name=HOJ+jamfor+visa" data-compare=""
                    :href="searchResult.GoToCompareViewURL">
                    {{ searchResult.Compare }}
                </a>
                <a @click.prevent="removeItemsFromBasket(searchResult.ClearBasketUrl)" id="clearAllInBasket">
                    {{ searchResult.CompareRemoveAll }}
                </a>
            </div>

            <div v-for="index in 5" :key="index"
                :class="`basket-item col-md-2 col-md-push-2 ${searchResult.ItemClass} `">
                <div v-if="(index - 1) < searchResult.EducationsCompareBasket.length" class="row">
                    <div class="basket-item-info col-xxs-8 col-md-11">
                        <div class="basket-title" title="@education.Title">{{ searchResult.EducationsCompareBasket[index
            -
            1].Title }}</div>
                        <div class="basket-provider">{{ searchResult.EducationsCompareBasket[index - 1].Provider }}
                        </div>

                    </div>
                    <div class="col-xxs-4 col-md-1">
                        <a @click.prevent="removeItemsFromBasket(searchResult.EducationsCompareBasket[index - 1].RemoveItemFromBasketUrl)"
                            class="item-remove"
                            :aria-label="`X Ta bort ${searchResult.EducationsCompareBasket[index - 1].Title} från jämförelse`"
                            :data-compare-delete="searchResult.EducationsCompareBasket[index - 1].EventString">
                            x
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "comparebasket",
    components: {
    },
    props: {
        searchResult: Object,
    },
    data() {
        return {

        }
    },
    mounted() {
        this.onScreenResize();
        this.updateBasketWidth();
    },
    unmounted() {
        window.removeEventListener("resize", this.updateBasketWidth);
    },
    watch: {
        searchResult: async function (currentState, prevState) {
            if (currentState.EducationsCompareBasket.length !== prevState.EducationsCompareBasket.length) {
                await this.$nextTick();
                this.updateBasketWidth();
            }
        }
    },
    methods: {
        removeItemsFromBasket(removeItemsFromBasketUrl) {
            this.$emit("removeHit", removeItemsFromBasketUrl);
        },
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateBasketWidth();
            });
        },
        updateBasketWidth() {
            if (document.getElementById("CompareBasketWrapper") !== null) {
                var width = document.getElementById("edu-results-list").offsetWidth - 20;
                document.getElementById("CompareBasketWrapper").setAttribute("style", "width:" + width + 'px');
            }
        },
        getURLParameter(sParam) {
            var sPageURL = window.location.search.substring(1);
            var sURLVariables = sPageURL.split('&');
            for (var i = 0; i < sURLVariables.length; i++) {
                var sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] == sParam) {
                    return sParameterName[1];
                }
            }
        },
        pushUpdateURL(url) {
            if (url != window.location && history.pushState != null) {
                window.history.pushState(url, '', url);
            }
        },

        showHideSelected() {
            let cv = this.getURLParameter("cv");
            if (cv) {
                if (cv == "99") {
                    this.pushUpdateURL(
                        this.removeUrlParameter(
                            window.location.pathname + window.location.search,
                            "cv"
                        )
                    );
                    $(this).text("Visa valda");
                } else {
                    this.pushUpdateURL(
                        this.addUrlParameter(
                            window.location.pathname + window.location.search,
                            "cv",
                            "99"
                        )
                    );
                    $(this).text("Dölj valda");
                }
            } else {
                this.pushUpdateURL(
                    this.addUrlParameter(
                        window.location.pathname + window.location.search,
                        "cv",
                        "99"
                    )
                );
                $(this).text("Dölj valda");
            }
            $("#edu-results-list #CompareBasket .basket-item").each(function () {
                if ($(this).hasClass("hidden-xs")) {
                    $(this).removeClass("hidden-xs hidden-sm");
                } else {
                    $(this).addClass("hidden-xs hidden-sm");
                }
            });
        },
        addUrlParameter(url, name, val) {
            // If the parameter is present but new value is empty then remove parameter
            if (val.length == 0 || val == -1) {
                return this.removeUrlParameter(url, name);
            }

            //Try to replace the parameter if it's present in the url
            let count = 0;
            url = url.replace(new RegExp("([\\?&]" + name + "=)[^&]+"), function (a, match) {
                count = 1;
                return match + encodeURIComponent(val);
            });

            //If the parameter is not present in the url append it
            if (!count && val != undefined) {
                url += (url.indexOf("?") >= 0 ? "&" : "?") + name + "=" + encodeURIComponent(val);
            }
            return url;
        },
        removeUrlParameter(url, name) {

            let urlparts = url.split('?');

            if (urlparts.length >= 2) {
                let urlBase = urlparts.shift();
                let queryString = urlparts.join("?");

                let prefix = encodeURIComponent(name) + '=';
                let pars = queryString.split(/[&;]/g);
                for (let i = pars.length; i-- > 0;)
                    if (pars[i].lastIndexOf(prefix, 0) !== -1)
                        pars.splice(i, 1);
                url = urlBase + '?' + pars.join('&');

                if (url.substring(url.length - 1) == "?") {
                    url = url.substring(0, url.length - 1);
                }
            }
            return url;
        }
    },
};
</script>