export default function () {
  //$(".js-mobileMenuToggle").on("click", toggleSrHelpText);
  $('#mobilmenucollapsecontainer [data-toggle="collapse"]').on("click", handleMobileMenuClick);
}

function handleMobileMenuClick(event) {
  event.preventDefault();
  toggleSrHelpText(event);
}

function toggleSrHelpText(event) {
  var $collapseLink = $(event.currentTarget);
  var isOpening = !($collapseLink.attr('aria-expanded') == 'true')
  var $srhelp = $collapseLink.find('.js-toggle-sr-text')
  var closeText = $srhelp.attr('data-extended-close-text') ?? ''
  var openText = $srhelp.attr('data-extended-open-text') ?? ''

  $srhelp.text(isOpening ? closeText : openText);
  var completeLinkText = $collapseLink.text().replace(/\s\s+/g, ' ').trim()
  $collapseLink.attr('aria-label', completeLinkText)
}