<template>
  <li>
    <!-- Snabbfakta (ihopfällt kort) -->
    <div class="info-container">
      <div class="info-main">
        <div class="info-left">
          <h3 class="info-title">
            {{ resultInfo.Title }}
          </h3>
          <div class="info-wrapper">
            <span class="info-text school">
              <SvgIcon IconName="geografisk-knappnal" Title="true" TitleText="Universitet/högskola">
              </SvgIcon>{{ resultInfo.Provider }}<span class="event-text"></span>
            </span>
            <span class="info-text HP">
              <SvgIcon Title="true" TitleText="Omfattning" IconName="klocka"></SvgIcon>{{
                removeZeroButNotFive(resultInfo.Hp) }} hp
              {{ resultInfo.TimeParsed }}
            </span>
            <span class="info-text time">
              <SvgIcon Title="true" TitleText="Studietakt" :IconName="studyPaceToIcon(
                resultInfo.PaceOfStudyParsed,
                resultInfo.HasDifferentPace
              )
                ">
              </SvgIcon>{{ resultInfo.PaceOfStudyParsed }}
            </span>
            <span class="info-text course">
              <SvgIcon Title="true" TitleText="Utbildningsnivå" IconName="examenshatt"></SvgIcon>{{ retriveConfigureData
              }}
            </span>
            <span v-if="resultInfo.Distance !== 'På plats'" :class="`info-text ${resultInfo.Distance !== 'På plats' ? 'distance-background' : ''
              }`">{{ resultInfo.Distance }}</span>
          </div>
          <div class="info-wrapper-admin" v-if="IsVisibleForAdmin">
            <p>{{ resultInfo.Id }}</p>
            <p>{{ resultInfo.Categories }}</p>
          </div>
        </div>
        <div class="info-buttons">
          <button @click="compareHit(resultInfo)" :class="[
            'btn',
            'btn-default',
            'btn-compare',
            resultInfo.AddedToCompareBasket ? 'btn-added' : 'plausible-event-name=HOJ+jamfor+add',
            noOfItemsInBasket < 5 || resultInfo.AddedToCompareBasket
              ? ''
              : 'disabled',
          ]" :aria-label="`Jämför ${resultInfo.Title} med andra utbildningar`">
            <SvgIcon IconName="check" v-if="resultInfo.AddedToCompareBasket"></SvgIcon>
            <span>{{ resultInfo.AddOrRemoveText }}</span>
          </button>
          <button @click="
            toggleAccordionActiveState(resultInfo.AdmissionStatisticsUrl)
            " :class="[
              'btn',
              'btn-default',
              'btn-accordion-toggle',
              accordionActiveState ? 'active' : '',
            ]" :aria-expanded="`${accordionActiveState ? true : false}`" :aria-controls="`${id}-collapse`"
            :aria-label="`${accordionActiveState ? 'Stäng informationen' : 'Läs mer'
              } om ${resultInfo.Title}`">
            <span>{{ accordionActiveState ? "Stäng" : "Läs mer" }}</span>
            <SvgIcon IconName="triangel-pil-ned"></SvgIcon>
          </button>
        </div>
      </div>
    </div>
    <!-- Mer information (utfällt kort) -->
    <div :id="`${id}-collapse`" :class="['collapse', { in: accordionActiveState }]" ref="collapseElement">
      <div class="accordion-content">

        <div class="stats-container">
          <div id="admission-stats-accordian">
            <AdmissionStatsAccordion v-if="accordionActiveState" :resultInfo="resultInfo" :Id="id"
              :isLoading="this.isLoading" :show-info="true" :admissionStatisticsUrl="admissionStatisticsUrl"
              :courseOrProgram="retriveConfigureData">
            </AdmissionStatsAccordion>
          </div>
          <div class="edu-labor-market">
            <LabourForecast :labourForecastUrl="this.labourForecastUrl"></LabourForecast>
          </div>
        </div>

        <div v-for="(event, index) in resultInfo.Events" :class="`event-row-${index + 1}`">
          <h4 class="event-header">Tillfälle {{ index + 1 }}</h4>
          <div class="event-container row">
            <div class="event-left col-xs-12 col-sm-6">
              <a class="event-wrapper" :href="event.Url" target="_blank">
                <span> Universitetets/högskolans beskrivning av </span>
                <span class="text-nowrap">
                  utbildningen<SvgIcon IconName="extern-lank"></SvgIcon>
                </span>
                <span class="sr-only">
                  {{ resultInfo.Title }}
                </span>
              </a>
              <div class="event-wrapper">
                <span class="event-text school">
                  <SvgIcon Title="true" TitleText="Universitet/högskola" IconName="geografisk-knappnal">
                  </SvgIcon>
                  Lärosäte:
                </span>
                <a target="_blank" :href="resultInfo.Url" v-if="resultInfo.Provider !== null &&
                  resultInfo.Provider.split(' ').length > 1
                ">{{ getAllWordsButLastInText(resultInfo.Provider) }}
                  <span class="text-nowrap">
                    {{ getLastWordInText(resultInfo.Provider) }}
                    <SvgIcon IconName="extern-lank"></SvgIcon>
                  </span></a>
                <a target="_blank" :href="resultInfo.Url" v-else><span class="text-nowrap">
                    {{ resultInfo.Provider }}
                    <SvgIcon IconName="extern-lank"> </SvgIcon>
                  </span></a>
              </div>
              <div class="event-wrapper">
                <SvgIcon Title="true" TitleText="Omfattning" IconName="klocka"></SvgIcon>
                <span class="event-text">Omfattning:</span>
                <span>{{ resultInfo.Hp }} hp</span>
              </div>
              <div class="event-wrapper">
                <SvgIcon Title="true" TitleText="Studietakt" :IconName="studyPaceToIcon(
                  resultInfo.PaceOfStudyParsed,
                  resultInfo.HasDifferentPace
                )
                  ">
                </SvgIcon>
                <span class="event-text">Studietakt:</span>
                <span>{{ event.ParsedPaceOfStudy }}</span>
              </div>
              <div class="event-wrapper">
                <SvgIcon Title="true" TitleText="Utbildningsnivå" IconName="examenshatt"></SvgIcon>
                <span class="event-text">Utbildningsnivå:</span>
                <span>{{ capitalizeFirstLetter(event.EducationLevel) }}</span>
              </div>
              <div class="event-wrapper">
                <span class="event-text">Studieform:</span>
                <span :class="`info-text ${event.Distance !== 'På plats' ? 'distance-background' : ''
                  }`">{{ event.Distance }}</span>
              </div>
              <div class="event-wrapper">
                <span class="event-text entry-requirements">Behörighet:</span>
                <span>{{ event.Eligibility }}</span>
              </div>
            </div>
            <div class="event-right col-xs-12 col-sm-6">
              <div class="event-wrapper">
                <span class="event-text">Ort:</span>{{ event.Location }}
              </div>
              <div class="event-wrapper">
                <span class="event-text">Språk:</span>{{ event.Language }}
              </div>
              <div class="event-wrapper">
                <span class="event-text">Start:</span>{{ event.Start }}
              </div>
              <div class="event-wrapper">
                <span class="event-text" :aria-label="`${event.Degree === null ? 'Ingen examen' : ''}`">Examen:</span>{{
                  event.Degree === null ? "-" : event.Degree }}
              </div>
              <div class="event-wrapper">
                <span class="event-text">Anmälningskod:</span>{{ event.ApplicationCode.toUpperCase() }}
              </div>
            </div>
            <div class="event-bottom col-xs-12">
              <a :href="event.ApplicationUrl" target="_blank" :aria-label="`Till anmälan för tillfälle ${index + 1} ${resultInfo.Title
                }`" :class="['btn', 'btn-compare']">
                <SvgIcon IconName="extern-lank"></SvgIcon>
                <span>Till anmälan </span><span class="hidden-xxs">(Antagning.se)</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import { getCurrentInstance, h } from "vue";
import SvgIcon from "../misc-components/svgicon.vue";
import api from "../plugins/searchcompareservice";
import AdmissionStatsAccordion from "../components/admission-stats-accordion.vue";
import generalMixin from "../mixin/generalMixin";
import LabourForecast from "./labourForecast/labour-forecast.vue";

export default {
  name: "educationcard",
  mixins: [generalMixin],
  components: {
    SvgIcon,
    AdmissionStatsAccordion,
    LabourForecast,
  },
  props: {
    IsVisibleForAdmin: Boolean,
    resultInfo: Object,
    noOfItemsInBasket: 0,
    nrOfResultHits: String,
  },
  data() {
    return {
      labourForecastUrl: this.resultInfo.LabourForcastUrl,

      accordionActiveState: false,
      admissionStatsState: false,
      id: null,
      isMobile: window.innerWidth <= 991,
      isLoading: true,
      admissionStatisticsUrl: null,
    };
  },
  emits: ["compareHit", "displayStats"],
  mounted() {
    const instance = getCurrentInstance();
    if (instance) {
      this.id = instance.uid;
    }
  },
  methods: {
    toggleAccordionActiveState(query) {
      this.accordionActiveState = !this.accordionActiveState;
      this.$emit("displayStats", this.accordionActiveState);
      if (this.accordionActiveState) {
        this.admissionStatisticsUrl = query;
      }
    },
    compareHit(resultInfo) {
      this.$emit("compareHit", resultInfo);
    },
    setSpinnerIn(el) {
      el.html(
        '<div style="text-align:center"><img src="/assets/img/uhr-spinner.gif" style="margin: 100px auto; width:auto" alt="Loading..." /></div>'
      );
      //Spinner gif generated by http://preloaders.net/en/rectangular
    },

    getLabourForcastParam(href) {
      const urlSearchParams = new URLSearchParams(href);
      return urlSearchParams.get("lf");
    },
    updateScreenSize() {
      this.isMobile = window.innerWidth <= 991;
    },
    showModal() {
      if (!document.getElementById("modal-data")) {
        $(
          "<div id='modal-data' class='modal fade' role='dialog' aria-labelledby='ModalLabel'><div class='modal-dialog' role='document'><div class='modal-content'></div></div></div>"
        ).appendTo(document.body);
      }
      $("#modal-data").modal("show", {
        backdrop: true,
      });
      this.setSpinnerIn($("#modal-data .modal-content"));
    },
  },
  created() {
    this.isScreenSizeDesktop = window.innerWidth >= 992;
    window.addEventListener("resize", this.updateScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  watch: {
    nrOfResultHits() {
      this.accordionActiveState = false;
    },
  },

  computed: {
    retriveConfigureData() {
      return this.resultInfo.Events
        ? this.capitalizeFirstLetter(this.resultInfo.Events[0].Configuration)
        : null;
    },
  },
};
</script>
