import "js/legacy/slick-custom.js";
import "../../node_modules/slick-carousel/slick/slick.css";

const options = {
	accessibility: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	centerMode: false,
	dots: true,
	infinite: true,
	speed: 400,
	//mobileFirst: true,
	responsive: [
		{
			breakpoint: 992 - 17, //$screen-md-min
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				centerMode: true,
			},
		},
		{
			breakpoint: 768 - 17, //$screen-sm
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerMode: false,
			},
		},
		{
			breakpoint: 480 - 17, //$screen-xs
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
			},
		},
	],
};

export default function slickinitializer() {
	$(function () {
		$(".videoCarousel").slick(options);
		// Make the video-containers in the carousel draggable with mouse in web browser
		$(".video-container").mousedown(function (e) {
			e.preventDefault();
		});

		SetNextPrevArrowsHeight();

		// Also set arrow heights on window-resize
		$(window).resize(function () {
			SetNextPrevArrowsHeight();
		});

		// Listen for orientation changes
		try {
			window.addEventListener(
				"orientationchange",
				function () {
					setInterval(function () {
						SetNextPrevArrowsHeight();
					}, 500);
				},
				false
			);
		} catch (e) {
			// empty
		}
	});
}

/// Set the height on next/prev-arrows to the same hight as the image with the highest hight.
function SetNextPrevArrowsHeight() {
	var highestHeight = 0;

	$("img.videoImage").each(function () {
		if ($(this).height() > highestHeight) {
			highestHeight = $(this).height();

			$(".slick-next").height(highestHeight);
			$(".slick-prev").height(highestHeight);
		}

		$(this).on("load", function () {
			if ($(this).height() > highestHeight) {
				highestHeight = $(this).height();
			}

			$(".slick-next").height(highestHeight);
			$(".slick-prev").height(highestHeight);
		});
	});
}
