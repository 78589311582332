<template>
    <div class="filterbuttons">
        <filterButton v-for="(item, index) in eduCompareData.compareItems" :key="item.id" :item=item.data :index=index
            @close="handleClose" @filterselected="handleToggle" :isChecked="setChecked(item)"
            :maxItemsChecked="isMaxedItemsChecked" :isMobile="isMobile">
        </filterButton>
    </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue"
import { eduCompData } from "./compareData"
import filterButton from "./filterButton.vue"

export default {
    name: "compareFilters",
    emits: ['close'],
    props: {
        returnUrl: String,
    },
    components: {
        filterButton,
    },
    setup(props) {
        const { eduCompareData, ensureLoaded, compareItems } = eduCompData();

        onMounted(() => {
            window.addEventListener('popstate', handlePopState);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('popstate', handlePopState);
        });

        const handlePopState = (event) => {
            // Logic to update your reactive object when the back button is pressed
            let params = new URLSearchParams(window.location.search);
            let ce = params.get("ce").split(',');
            ensureLoaded({ ce: ce, returnUrl: props.returnUrl });
        };

        const removeItemFromDataAndUrl = (id, href) => {
            const index = eduCompareData.compareItems.findIndex(item => item.id === id);

            if (index !== -1) {
                const url = new URL(window.location.href);
                let ce = url.searchParams.get('ce');

                if (ce) {
                    let ceArray = decodeURIComponent(ce).split(',');

                    ceArray = ceArray.filter(item => !href.includes(item));

                    let reloadPage = false;
                    if (ceArray.length == 0) {
                        url.searchParams.set('cv', 99);
                        reloadPage = true;
                    }

                    let ceEncoded = encodeURIComponent(ceArray.join(','));
                    url.searchParams.set('ce', ceEncoded);
                    window.history.pushState({ urlPath: url.toString() }, '', url.toString());

                    if (reloadPage) {
                        window.location.reload();
                    }
                }
                eduCompareData.compareItems.splice(index, 1);

                const ids = eduCompareData.compareItems.flatMap(item => item.data.Events.map(event => event.Id));
                const idsString = ids.join(',');
                eduCompareData.returnUrl = updateCeParameter(eduCompareData.returnUrl, idsString);
            }
        };

        const handleClose = (id, href) => {
            removeItemFromDataAndUrl(id, href);
        }

        const updateCeParameter = (url, newCeValue) => {
            const encodedCeValue = encodeURIComponent(newCeValue);
            const ceRegex = /([?&]ce=)[^&]*/;
            if (url.match(ceRegex)) {
                return url.replace(ceRegex, `$1${encodedCeValue}`);
            } else {
                const separator = url.includes('?') ? '&' : '?';
                return `${url}${separator}ce=${encodedCeValue}`;
            }
        };

        const isMaxedItemsChecked = ref(eduCompareData.compareItems.filter(item => item.checked).length <= eduCompareData.maxNumberofItems);

        const handleToggle = (id, checked) => {
            const item = eduCompareData.compareItems.find(item => item.id === id);
            if (item) {
                item.visible = checked;
                item.checked = checked;
            }
            isMaxedItemsChecked.value = eduCompareData.compareItems.filter(item => item.checked).length >= eduCompareData.maxNumberofItems;
        }

        const isMobile = ref(window.innerWidth < 480)

        const handleResize = () => {
            var size = document.body.getBoundingClientRect();
            var isSmallScreen = size.width < 992;
            isMobile.value = window.innerWidth < 480;
            eduCompareData.maxNumberofItems = isSmallScreen ? 2 : 5;

            if (isSmallScreen) {
                let checkedCount = 0;
                eduCompareData.compareItems.forEach((compareItem, index) => {
                    if (compareItem.checked) {
                        checkedCount++;
                        if (checkedCount > eduCompareData.maxNumberofItems) {
                            compareItem.checked = false;
                            isMaxedItemsChecked.value = true;
                        }
                    }
                });
            } else {
                isMaxedItemsChecked.value = false;
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return {
            eduCompareData,
            handleClose,
            handleToggle,
            isMaxedItemsChecked,
            compareItems,
            isMobile
        }
    },
    methods: {
        setChecked(item) {
            // item.checked = false;
            var setChecked = this.compareItems.some(compareItem => compareItem.id === item.id);
            if (setChecked) {
                item.checked = true;
            }
            return setChecked;
        }
    }
}
</script>