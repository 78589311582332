export const FilterListValue = Object.freeze({
  AMNE: 1,
  HOGSKOLA: 2,
  STUDIEORT: 3,
  KURSPROGRAM: 4,
  STUDIETAKT: 5,
  EXAMEN: 6,
  TERMIN: 7,
  STUDIEFORM: 8,
  NIVA: 9,
  SPRAK: 11,
});

export const sortOptions = Object.freeze([
  {
    namn: "Relevans",
    parameter: "relevance",
  },
  {
    namn: "Utbildning, A-Ö",
    parameter: "education.asc",
  },
  {
    namn: "Utbildning, Ö-A",
    parameter: "education.desc",
  },
  {
    namn: "Universitet/högskolor, A-Ö",
    parameter: "larosaten.asc",
  },
  {
    namn: "Universitet/högskolor, Ö-A",
    parameter: "larosaten.desc",
  },
  {
    namn: "Antal högskolepoäng, stigande",
    parameter: "numberofpoints.asc",
  },
  {
    namn: "Antal högskolepoäng, fallande",
    parameter: "numberofpoints.desc",
  },
  {
    namn: "Utbildningens längd, stigande",
    parameter: "edulength.asc",
  },
  {
    namn: "Utbildningens längd, fallande",
    parameter: "edulength.desc",
  },
]);

export const urvalsgrupper = Object.freeze([
  {
    code:'BI', explanation: 'Gymnasiebetyg utan komplettering'
  },
  {
    code:'HP', explanation: 'Högskoleprov'
  },
  {
    code:'BII', explanation: 'Gymnasiebetyg med komplettering'
  },
  {
    code:'BF', explanation: 'Betyg från folkhögskola'
  },
  {
    code:'AP', explanation: 'Oftast Tidigare akademiska poäng. Kontrollera med högskolan innan du anmäler dig.'
  },
  {
    code:'AU', explanation: 'Alternativt urval'
  },
  {
    code:'SA', explanation: 'Sen anmälan'
  },
  {
    code:'ÖS', explanation: 'Övriga sökande'
  },
 ]);