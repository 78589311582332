<template>
  <td>
    <p>
      <SvgIcon :IconName="iconName" title="true" TitleText="Omfattning" />
      <span>{{ textData }}</span>
    </p>
  </td>
</template>
<script>
import { computed } from "vue";
import { eduCompData } from "./compareData";

export default {
  name: "cellAntalHp",
  props: {
    rubrik: String,
    comparePropertyData: Object, // { hp: Number, time: Number}
  },
  setup() {
    const { eduCompareData } = eduCompData();
    return { globalText: eduCompareData.text };
  },

  methods: {
    writeText(textObject) {
      const { time, hp } = textObject;
      let returnTime =
        time === undefined || time.length < 1 ? this.globalText.NA : time;
      let returnHp =
        hp === undefined || hp.length < 1 ? this.globalText.NA : hp;
      return returnHp + " hp (" + returnTime + " år)";
    },
  },

  data() {
    return {
      iconName: "klocka",
      textData: computed(() => this.writeText(this.comparePropertyData)),
    };
  },
};
</script>
