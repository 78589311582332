<template>
  <label class="checkbox-label" tabindex="-1"
    :class="`${filter.IsSelectedFilter == true ? 'selected ' : ''}${filter.disabled ? 'disabled' : ''}`">
    <input type="checkbox" :id="`cb_filter-${selectedFilter}-${index + 1}`" :class="plausibleTrackingClass ?? ''"
      :value="filter.Href" :checked="filter.IsSelectedFilter" :disabled="filter.disabled" @click="toggle" />
    <span class="inner"></span>
    <span class="txt">
      {{ filter.ShowAll + filter.Title }}
    </span>
  </label>
  <informationIcon :filterItemInfo="filter.FilterItemInfo" filterGroup="" :filterTitle="filter.Title">
  </informationIcon>
</template>

<script>
import informationIcon from "../components/informationicon.vue";
export default {
  name: "checkbox",
  components: {
    informationIcon
  },
  emits: ['filterselected'],
  props: {
    filter: Object,
    selectedFilter: Number,
    index: Number,
    plausibleTrackingClass: String,
  },
  methods: {
    toggle(e) {
      //TODO: Refaktorisera filtrering, CMS-1427
      let windowsParams = new URLSearchParams(window.location.search);
      let query = windowsParams.get("q");
      let filterParams = new URLSearchParams(e.target.value);
      if (query != null) {
        filterParams.set('q', query);
      }
      this.$emit("filterselected", filterParams.toString());
    },
  },
};
</script>
