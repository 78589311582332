export default {
	beforeMount(el, binding) {
		el.clickOutsideEvent = function (event) {
			// Check if the click was outside the element and if the element is not contained by the event target
			if (!(el == event.target || el.contains(event.target))) {
				binding.value();
			}
		};
		document.body.addEventListener("click", el.clickOutsideEvent);
	},
	unmounted(el) {
		document.body.removeEventListener("click", el.clickOutsideEvent);
	},
};
