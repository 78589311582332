<template>
    <div id="SCPaging" class="ess-pagination footer">
        <ul>
            <li v-if="pagedList.HasPreviousPage">
                <a data-paging='' class="btn btn-secondary relativeNavigation previous" rel='prev'
                    :aria-label="`Föregående : Resultatsida ${pagedList.PreviousItem.Index}`"
                    :title="`Föregående : Resultatsida ${pagedList.PreviousItem.Index}`"
                    :href='getPaginationUrl("previous", 0)'>
                    <span class="pagination-arrow hidden-md hidden-lg">&lt;</span><span
                        class="hidden-sm hidden-xs">Föregående</span>
                </a>
            </li>
            <li v-if="pagedList.PageNumber >= 6">
                <a :href='getPaginationUrl("first", 0)'>
                    1..
                </a>
            </li>
            <li v-for="(result) in pagedList.PagingItems">
                <a data-paging='' :class="getClass(result)" :rel="getRel(result)" :aria-label="getAriaLabel(result)"
                    :title="getAriaLabel(result)" :href='getPaginationUrl("page", result.Index)'>{{ result.Index }}</a>
            </li>
            <li v-if="pagedList.HasNextPage">
                <a data-paging='' class='btn btn-primary next' rel='next'
                    :aria-label="`Nästa : Resultatsida ${pagedList.NextItem.Index}`"
                    :title="`Nästa : Resultatsida ${pagedList.NextItem.Index}`" :href='getPaginationUrl("next", 0)'>
                    <span class="pagination-arrow hidden-md hidden-lg">&gt;</span><span
                        class="hidden-sm hidden-xs">Nästa</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "result-paging",
    components: {
    },
    props: {
        pagedList: Object,
        sorteringsOrdning: String
    },
    methods: {
        getPaginationUrl(page, index) {
            switch (page) {
                case 'previous':
                    return this.pagedList.PaginationUrl + "=" + this.pagedList.PreviousItem.Index + "&so=" + this.sorteringsOrdning;
                case 'next':
                    return this.pagedList.PaginationUrl + "=" + this.pagedList.NextItem.Index + "&so=" + this.sorteringsOrdning;
                case 'first':
                    return this.pagedList.PaginationUrl + "=1" + "&so=" + this.sorteringsOrdning;
                case 'page':
                    return this.pagedList.PaginationUrl + "=" + index + "&so=" + this.sorteringsOrdning;
                default:
                    return this.pagedList.PaginationUrl + "&so=" + this.sorteringsOrdning;
            }
        },
        getClass(result) {
            if (this.pagedList.PageNumber == result.Index) {
                return "ess-active"
            } else {
                return ""
            }
        },
        getRel(result) {
            if (this.pagedList.PageNumber == result.Index) {
                return "active"
            } else if (this.pagedList.HasPreviousPage && result.Index < this.pagedList.PageNumber) {
                return "prev"
            } else {
                return "next"
            }
        },
        getAriaLabel(result) {
            if (this.pagedList.PageNumber == result.Index) {
                return "Visas nu:  Resultatsida " + result.Index
            } else if (this.pagedList.HasPreviousPage && result.Index < this.pagedList.PageNumber) {
                return "Föregående:  Resultatsida " + result.Index
            } else {
                return "Nästa:  Resultatsida " + result.Index
            }
        }
    }
}
</script>
