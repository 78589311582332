<template>
  <svg v-if="IconName && Title" :class="iconClass" focusable="false"
    :aria-labelledby="`${TitleText !== '' ? IconName + '-title' : ''}`">
    <title v-if="Title" :id="`${IconName}-title`">{{ TitleText }}</title>
    <use :xlink:href="svgUrl"></use>
  </svg>
  <svg v-else-if="IconName" :class="iconClass" aria-hidden="true" focusable="false">
    <use :xlink:href="svgUrl"></use>
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    IconName: String,
    className: String,
    Title: false,
    TitleText: String,

  },
  computed: {
    iconClass() {
      return `icon icon-${this.IconName} ${this.className ? this.className : ""
        }`;
    },
    svgUrl() {
      //OBS. det här är resultatsökvägen efter kompilering. Ska egentligen inte vara det, men den replacas inte så den är hårdkodad ist
      return `/assets/icons/studeranuiconsprite.svg#icon-${this.IconName}`;
    },
  },
};
</script>