/**
 **********
 * Readspeaker
 **********
 **/
import svgicon from "./atoms/svgicon";

export default function readspeakerinitalizer() {
	if ($("#readspeaker_button1").length) {
		SetReadSpeakerIcon();
	}
}

function SetReadSpeakerIcon() {
	var listenlang = $("#xp1").attr("data-listenlang");
	var listendoc = $("#xp1").attr("data-listendoc");
	$('a[href$=".pdf"]').each(function () {
		if ($(this).find(".icon-extern-lank").length) {
			return;
		}
		var a_href =
			location.protocol +
			"//" +
			location.hostname +
			(location.port ? ":" + location.port : "") +
			$(this).attr("href");
		if ($(this).hasClass("preventAutomaticIcon")) {
			$(this).after(
				"<span class='readspeak_doc_btn'><a rel='nofollow' href='https://docreader.readspeaker.com/docreader/?cid=bwvvg&amp;lang=" +
					listenlang +
					"&amp;url=" +
					a_href +
					"' title=" +
					listendoc +
					"><span>" +
					svgicon("icon-hogtalare") +
					"</span><a></span>"
			);
		} else {
			$(this).append(
				"<span>" +
					svgicon("icon-dokument-pdf") +
					"</span><a></span><span class='readspeak_doc_btn'><a rel='nofollow' href='https://docreader.readspeaker.com/docreader/?cid=bwvvg&amp;lang=" +
					listenlang +
					"&amp;url=" +
					a_href +
					"' title=" +
					listendoc +
					" ><span><svg class='icon icon-hogtalare automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/icons/studeranuiconsprite.svg#icon-hogtalare'></use><svg></span><a></span>"
			);
		}
	});
}
