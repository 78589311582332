<template>
    <div class="dropdown" :class="{ 'open': isOpen }">
        <button ref="toggleButton" class="dropdown-toggle btn btn-secondary" @click="toggleDropdown"
            :aria-expanded="isOpen" :id="toggleButtonId">
            {{ selectedOption ? selectedOption.namn : placeholder }}
            <SvgIcon IconName="triangel-pil-ned"></SvgIcon>
        </button>
        <ul class="dropdown-menu" :aria-hidden="!isOpen" :aria-labelledby="toggleButtonId">
            <li v-for="(option, index) in typedOptions" :key="option.namn" @click="selectOption(option)">
                <a href="#" :class="{ 'selected': option === selectedOption }">{{ option.namn }}</a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { getCurrentInstance } from 'vue';
import SvgIcon from "js/vue/misc-components/svgicon.vue";

declare type data = {
    id: number | null;
    isOpen: boolean;
    selectedOption: any;
    placeholder: string;
};

interface Option {
    namn: string;
    parameter: string;
}

export default {
    name: "drop-down",
    components: {
        SvgIcon
    },
    data() {
        return <data>{
            id: null,
            isOpen: false,
            selectedOption: null,
            placeholder: '',
        };
    },
    emits: ['select-option'],
    props: {
        options: {
            type: Array,
            required: true,
        },
        sorteringsOrdning: String,
    },
    mounted() {
        const instance = getCurrentInstance();
        this.id = instance?.uid ?? 0;
        document.addEventListener('click', this.closeDropdown);
        const matchedObject = this.options.find((obj: any) => obj.parameter === this.sorteringsOrdning) as Option;
        this.placeholder = matchedObject ? matchedObject.namn : '';
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdown);
    },
    computed: {
        toggleButtonId() {
            return this.id?.toString() ?? '';
        },
        typedOptions(): Array<Option> {
            return this.options as Array<Option>;
        },
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(option: any) {
            this.selectedOption = option;
            this.isOpen = false;
            this.$emit('select-option', this.selectedOption);
        },
        closeDropdown(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
    },
};
</script>