<template>
  <div class="sticky-filler"></div>
  <table class="compare-edu-table">
    <thead>
      <tr>
        <th></th>
        <th v-for="item in compareItems" :key="item.id" scope="col">
          <div class="sticky-item">
            <span class="name-of-course sticky-item-part">
              {{ item.name }}
            </span>
            <span class="sticky-item-part">
              {{ item.data.Provider }}
            </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <compareRow rowHeader="Utbildning" :getPropertyData="(ci) => ci.data.Title" :accomodate="true"
        cellTyp="cellText" />
      <compareRow rowHeader="Universitet" :getPropertyData="(ci) => {
          return {
            url: ci.data.Url,
            text: ci.data.Provider,
            iconName: 'geografisk-knappnal',
          };
        }" :accomodate="true" cellTyp="cellLink" />
      <compareRow rowHeader="Ort" :getPropertyData="(ci) => ci.data.Events.map((e) => e.Location)" :accomodate="true"
        cellTyp="cellText" />
      <compareRow rowHeader="Start" :getPropertyData="(ci) => ci.data.Events.map((e) => e.Start)" :accomodate="true"
        cellTyp="cellText" />
      <compareRow rowHeader="Studieform" :getPropertyData="(ci) => ci.data.Events.map((e) => e.Distance)"
        :accomodate="true" cellTyp="cellText" />
      <compareRow rowHeader="Studietakt" :getPropertyData="(ci) => ci.data.Events.map((e) => e.PaceOfStudy)"
        :accomodate="true" cellTyp="cellProcent" />
      <compareRow rowHeader="Antal högskolepoäng" cellTyp="cellAntalHp" :getPropertyData="(ci) => {
          return { hp: ci.data.Hp, time: ci.data.Time };
        }" />
      <compareRow rowHeader="Behörighet"
        :getPropertyData="(ci) => ci.data.Events.map((e) => fallbackIfEmpty(e.Eligibility, defaultEligibility))"
        :accomodate="true" cellTyp="cellText" />
      <compareRow rowHeader="Om utbildningen" :accomodate="true" cellTyp="cellHtmlText"
        :getPropertyData="(ci) => fallbackIfEmpty(ci.data.Description, defaultDescription)" />
      <compareRow rowHeader="Utbildningens hemsida" :accomodate="true" cellTyp="cellLink" :combine="true"
        :getPropertyData="(ci) => ci.data.Events.map((e) => {
          return {
            url: e.Url,
            text: 'Om tillfället', // Tillfälle 1, Tillfälle 2, Tillfälle 3 etc
            iconName: null
          };
        })" />
      <compareRow rowHeader="Framtid" cellTyp="cellLabourForecast" :getPropertyData="(ci) => {
          return {
            eventId: ci.data.Events[0].Id, // vi anänder första för att skapa url
            labourForecastUrl: ci.data.LabourForcastUrl,
          };
        }" />
      <compareRow rowHeader="Examen" :getPropertyData="(ci) => ci.data.Events.map((e) => e.Degree)" :accomodate="true"
        cellTyp="cellText" />
      <compareRow rowHeader="Nivå" :getPropertyData="(ci) => ci.data.Events.map((e) => e.EducationLevel)"
        :accomodate="true" cellTyp="cellText" />
      <compareRow rowHeader="Undervisningsspråk" :getPropertyData="(ci) => ci.data.Events.map((e) => e.Language)"
        :accomodate="true" cellTyp="cellText" />
      <compareRow rowHeader="Anmälningskod" :getPropertyData="(ci) => ci.data.Events.map((e) => e.ApplicationCode)"
        :accomodate="true" cellTyp="cellText" />
      <compareRow rowHeader="Till anmälan" :accomodate="true" cellTyp="cellLink" :getPropertyData="(ci) => ci.data.Events.map((e) => {
          return {
            url: e.ApplicationUrl,
            text: 'Antagning.se',
            iconName: null
          };
        })" />
    </tbody>
  </table>
</template>
<script>
import { eduCompData } from "./compareData";
import compareRow from "./row.vue";
import SvgIcon from "../../misc-components/svgicon.vue";

const defaultEligibility = 'Beskrivning av behörighetskrav saknas. Kontrollera vilken behörighet som krävs på utbildningens hemsida.';
const defaultDescription = 'Beskrivning saknas. Mer information finns på utbildningens hemsida.';

export default {
  name: "compareTable",
  components: {
    compareRow,
    SvgIcon,
  },
  beforeUpdate() {
    this.resizeColumnWidth();
  },

  mounted() {
    const eduCompareData = this.eduCompareData,
      table = document.querySelector("table.compare-edu-table"),
      head = document.querySelector("table.compare-edu-table thead"),
      headElementSelector = () =>
        document.querySelector(
          "table.compare-edu-table thead tr th:nth-child(2)"
        ),
      filler = document.querySelector(".sticky-filler"),
      mobileThreshold = 992;

    const resizeFiller = (f) => {
      const h = headElementSelector();
      if (!h) {
        return;
      }

      const headSize = h.getBoundingClientRect(),
        viewSize = document.body.getBoundingClientRect();

      const margin = -(viewSize.width - headSize.width) / 2;
      f.style.marginLeft = margin + "px";
      f.style.marginRight = margin + "px";
      f.style.height = headSize.height + "px";
      f.style.top = headSize.top + 1 + "px";
    };

    const checkSticky = ([e]) => {
      const r = head.getBoundingClientRect();
      const isStuck = e.intersectionRatio < 1 && r.top < 1;
      head.classList.toggle("stuck", isStuck);
      table.classList.toggle("stuck", isStuck);
      resizeFiller(filler);
    };

    const checkColumns = () => {
      var size = document.body.getBoundingClientRect();
      var isMobile = size.width < mobileThreshold;
      var maxVisibleColumns = isMobile ? 2 : 5;
      if (maxVisibleColumns !== eduCompareData.maxNumberofItems) {
        eduCompareData.maxNumberofItems = maxVisibleColumns;
      }
    };

    const observer = new IntersectionObserver(checkSticky, {
      root: null,
      rootMargin: "0px",
      threshold: [
        0,
        0.1,
        0.2,
        0.3,
        0.4,
        0.5,
        0.6,
        0.7,
        0.8,
        0.9,
        0.98,
        0.99,
        1,
      ],
    });

    resizeFiller(filler);
    checkColumns();
    this.resizeColumnWidth();
    window.addEventListener("resize", checkColumns);
    observer.observe(head);
  },
  methods: {
    resizeColumnWidth() {
      var colCount = this.compareItems.length;
      const topColumns = document.querySelectorAll(
        "table.compare-edu-table thead th"
      );
      if (!topColumns) return;

      const colWidth = 100 / colCount + "%";
      const length = topColumns.length;
      for (var index = 1; index < length; index++) {
        topColumns[index].style.width = colWidth;
      }
      topColumns[0].style.width = "0%";
    },
    fallbackIfEmpty(text, fallback) {
      return (text?.trim() ?? "").length > 0 ? text : fallback;
    }
  },
  setup() {
    const { eduCompareData, compareItems } = eduCompData();
    return {
      eduCompareData,
      compareItems,
      defaultEligibility,
      defaultDescription
    };
  },
};
</script>
