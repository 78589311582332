<template>
  <div class="mobile-full-width">
    <div class="compare-top">
      <div class="back-link">
        <a :href="eduCompareData.returnUrl">
          <SvgIcon IconName="pil-vanster-ny"></SvgIcon>
          <span>Tillbaka</span>
        </a>
      </div>
      <h1>{{ header }}</h1>
      <div class="hide-in-desktop">
        <p>{{ preamble }}</p>
        <compare-filters :returnUrl="this.backLink"></compare-filters>
      </div>
    </div>
    <div class="alert-noinfo-warning" role="alert" v-if="visibleItems === 0">
      {{ eduCompareData.text.NoData }}
    </div>
    <compare-table v-if="visibleItems > 0"></compare-table>
  </div>
  <div id="admission-stats-accordian" v-if="visibleItems > 0" class="col-xs-12">
    <h2>Antagningsstatistik</h2>
    <div v-if="isLoading" class="edu-admission-stats-spinner">
      <img src="/assets/img/uhr-spinner.gif" alt="Loading..." />
    </div>
    <AdmissionStatsInfo heading="Det här visar antagningsstatistiken" :isOpen="true" :providedAboutTable="aboutTable"
      :providedMoreAboutAdmissionStatistics="moreAboutAdmissionStatistics">
    </AdmissionStatsInfo>
    <div :class="{ hidden: isLoading }">
      <AdmissionStatsAccordion v-for="(item, index) in compareItems" :resultInfo="item.data" :Id="index"
        :isLoading="isLoading" :courseOrProgram="getCourseOrProgram(item.data)" :buttonText="setButtonText(item)"
        :show-info="false" :provider="item.data.Provider" @dataFetched="handleDataFetched"
        :admissionStatisticsUrl="item.data.AdmissionStatisticsUrl">
      </AdmissionStatsAccordion>
    </div>
  </div>
</template>

<script>
import compareFilters from "./components/compareEducation/filter.vue";
import compareTable from "./components/compareEducation/table.vue";
import AdmissionStatsAccordion from "./components/admission-stats-accordion.vue";
import AdmissionStatsInfo from "./components/admission-stats-info.vue";
import SvgIcon from "./misc-components/svgicon.vue";
import { watch, ref, toRef, computed } from "vue";
import UnderLineAccordion from "./components/underline-accordion.vue";

import { eduCompData } from "./components/compareEducation/compareData";

export default {
  name: "compareEducations",
  components: {
    compareFilters,
    compareTable,
    AdmissionStatsAccordion,
    AdmissionStatsInfo,
    SvgIcon,
    UnderLineAccordion,
  },
  props: {
    url: String,
    numberofStartRows: Number,
    header: String,
    backLink: String,
    preamble: String,
    aboutTable: String,
    tableSymbols: String,
    whatSelectionGroups: String,
    aboutSelectionGroups: String,
    moreAboutAdmissionStatistics: String,
  },
  provide() {
    return {
      providedAboutTable: this.aboutTable,
      providedTableSymbols: this.tableSymbols,
      providedWhatSelectionGroups: this.whatSelectionGroups,
      providedAboutSelectionGroups: this.aboutSelectionGroups,
      providedMoreAboutAdmissionStatistics: this.moreAboutAdmissionStatistics,
    };
  },
  setup(props) {
    let params = new URLSearchParams(window.location.search);
    let ce = params.get("ce").split(",");

    const { ensureLoaded, eduCompareData, compareItems } = eduCompData();
    ensureLoaded({ ce: ce, returnUrl: props.backLink });

    const isLoading = ref(true);

    const capitalizeFirstLetter = (str) => {
      if (!str) return "";
      let strArr = str.split(" ");
      for (let i = 0; i < strArr.length; i++) {
        strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substring(1);
      }
      return strArr.join(" ");
    };

    const handleDataFetched = (index, hasData) => {
      if (compareItems.value[index] && !compareItems.value[index].isFetched) {
        compareItems.value[index].hasAdmissionData = hasData;
        compareItems.value[index].isFetched = true;
        checkAllFetchedAndSort();
      }
    };

    const checkAllFetchedAndSort = () => {
      const allFetched = compareItems.value.every((item) => item.isFetched);
      if (allFetched) {
        sortDataItems();
        isLoading.value = false;
      }
    };

    const setButtonText = (education) => {
      if (education.data.Title) {
        return education.data.Title + ", ";
      }
      else {
        return null;
      }
    };

    const sortDataItems = () => {
      compareItems.value.sort((a, b) => {
        return a.hasAdmissionData === b.hasAdmissionData
          ? 0
          : a.hasAdmissionData
            ? -1
            : 1;
      });
    };

    const getCourseOrProgram = (data) => {
      return data.Events
        ? capitalizeFirstLetter(data.Events[0].Configuration)
        : null;
    };

    const sortAdmission = () => {
      checkAllFetchedAndSort;
    };

    sortAdmission();

    window.addEventListener("resize", sortAdmission);

    // Watch to trigger sorting after all items are fetched
    watch(
      compareItems,
      (newVal) => {
        checkAllFetchedAndSort();
      },
      { deep: true }
    );

    return {
      eduCompareData,
      compareItems,
      isLoading,
      getCourseOrProgram,
      handleDataFetched,
      checkAllFetchedAndSort,
      sortDataItems,
      setButtonText,
    };
  },
  data() {
    return {
      visibleItems: computed(
        () => this.compareItems.filter((ci) => ci.visible).length
      ),
    };
  },
};
</script>
