
/** jquery.i18n language loader */
/** 
 * https://github.com/wikimedia/jquery.i18n
 *  * 
 * Currently stores translations directly into js. 
 * If the translations keep growing for javascript translations, 
 * They might need to move out to json and read by ajax.
 * 
 *  translations is loaded with $.i18n( translationkey) i.e. $.i18n('close') 
 * 
 *  When adding translations add them alphabeticly.
 * 
 * */
export default function globalization() {
  return $.i18n().load({
    en: {  // English
      'close': 'Close',
      "openInNewWindow": "Open in new window"
    },
    sv: {  // Swedish
      'close': 'Stäng',
      "openInNewWindow": "Öppna i nytt fönster"
    }
  })
}