<template>
  <div class="mobilefilter">
    <button id="filterMobileMenuToggle" :aria-expanded="`${isOpen ? 'true' : 'false'}`"
      :class="`${isOpen ? 'open' : 'close'}`" @click="handleClick">
      Filter
      <SvgIcon IconName="triangel-pil-ned"></SvgIcon>
    </button>
    <ul v-if="isOpen" id="filterMenuToggle" class="subfilterlayout1 hidden-lg">
      <li v-for="(button, buttonindex) in filterListMobile" :key="buttonindex" class="filter">
        <button :id="`filterbutton${buttonindex}`" @click="toggleButton(button.value)" :value="button.value"
          :class="`${activeMobileButtonId.indexOf(button.value) > -1 ? 'open' : 'close'}`">
          {{ button.title }}<SvgIcon IconName="triangel-pil-ned"></SvgIcon>
        </button>
        <span v-if="isMenuOpen(button.value)" class="subfilterlayout2-text">{{ button.title }}</span>
        <informationIcon v-if="isMenuOpen(button.value)"
          :filterItemInfo="filterElLists[activeMobileButtonId.indexOf(button.value)].SelectedFilterInfo"
          :filterGroup="button.title" filterTitle="" />
        <ul :class="`${buttonindex == 0 ? 'subfilterlayout1 noborder' : 'subfilterlayout1'}`"
          v-if="isMenuOpen(button.value)">
          <li v-for="(filter, index) in filterElLists[activeMobileButtonId.indexOf(button.value)].SelectedFilterResult"
            :key="filter.Title">
            <div v-if="filter.SubFilterItems" class="subfiltercontainer">
              <button @click="handleSubFilter(index)" :id="`subfilterbutton${index}`"
                :class="`${isSubFilterOpen(index) ? 'open' : 'close'}`">
                <span>{{ filter.Title }}</span>
                <SvgIcon IconName="triangel-pil-ned"></SvgIcon>
              </button>
              <ul class="subfilter subfilterlayout1" v-if="isSubFilterOpen(index)">
                <li class="subfiltergriditems">
                  <checkBox :filter="filter" :index="index" :selectedFilter="index + 1" @filterselected="toggle"
                    :plausibleTrackingClass="'plausible-event-name=HOJ+filter+' + button.title">
                  </checkBox>
                </li>
                <li class="subfiltergriditems" v-for="(subfilter, subindex) in filter.SubFilterItems"
                  :key="filter.Title">
                  <checkBox :filter="subfilter" :index="subindex" :selectedFilter="subindex + 1"
                    @filterselected="toggle"
                    :plausibleTrackingClass="'plausible-event-name=HOJ+filter+' + button.title">
                  </checkBox>
                </li>
              </ul>
            </div>
            <checkBox class="subfilterlayout2" :filter="filter" :index="index" :selectedFilter="index + 1"
              @filterselected="toggle" :plausibleTrackingClass="'plausible-event-name=HOJ+filter+' + button.title"
              v-else>
            </checkBox>
          </li>
          <li v-if="extraFilter && button.value == 9" v-for="(item, index) in extraFilter.SelectedFilterResult">
            <checkBox :filter="item" :index="filterElLists[index].SelectedFilterResult + index"
              :selectedFilter="filterElLists[index].SelectedFilterResult + index + 1" @filterselected="toggle"
              :plausibleTrackingClass="'plausible-event-name=HOJ+filter+ejtidigare'">
            </checkBox>
          </li>

        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import checkBox from "./checkbox.vue";
import SvgIcon from "../misc-components/svgicon.vue";
import informationIcon from "../components/informationicon.vue";
export default {
  name: "mobilefiltermenu",
  components: {
    checkBox,
    SvgIcon,
    informationIcon
  },
  emits: ["filterElement", "filterselected", "updateSubFilter"],
  data() {
    return {
      isOpen: false,
      clickToOpen: false,
      filterUnderCategories: {},
      activeSubFilterId: [],
      activeMobileButtonId: []
    };
  },
  props: {
    filterListMobile: { type: Object, default: undefined },
    filterElLists: { type: Array, default: undefined },
    isTablet: Boolean,
    extraFilter: Object
  },
  mounted() {
    this.isOpen = false;
    let params = new URLSearchParams(window.location.search);
    if (params.get("m") !== null && params.get("m") != '') {
      let m = params.get("m").split(',');
      m.forEach(element => {
        this.setActiveFilters(parseInt(element));
      });
    }
  },
  methods: {
    handleClick() {
      this.isOpen = !this.isOpen;
    },
    toggle(data) {
      this.$emit("filterselected", data);
    },
    setActiveFilters(value) {
      if (value != null) {
        if (this.activeMobileButtonId.indexOf(value) < 0) {
          this.activeMobileButtonId.push(value);
        } else {
          var i = this.activeMobileButtonId.indexOf(value);
          this.activeMobileButtonId.splice(i, 1);
        }
      }
    },
    toggleButton(value) {
      if (value == null) {
        return;
      }
      this.setActiveFilters(value);

      if (this.activeMobileButtonId.length) {
        this.$emit("filterElement", this.activeMobileButtonId.join(','));
      } else {
        let params = new URLSearchParams(window.location.search);
        let replaceQuery = "";
        params.delete("m");
        replaceQuery = params.toString();
        let queryPost = replaceQuery;
        history.pushState({}, null, this.$route.path + "?" + queryPost);
        this.$emit("setQuery", queryPost);
      }
    },
    handleSubFilter(index) {
      if (this.activeSubFilterId.indexOf(index) < 0) {
        this.activeSubFilterId.push(index);
      } else {
        var i = this.activeSubFilterId.indexOf(index);
        this.activeSubFilterId.splice(i, 1);
      }
    },
    isSubFilterOpen(index) {
      return this.activeSubFilterId.indexOf(index) >= 0;
    },
    isMenuOpen(value) {
      if (this.activeMobileButtonId.indexOf(value) > -1 && (this.filterElLists?.length > 0) && this.filterElLists[this.activeMobileButtonId.indexOf(value)]) {
        return true;
      } else {
        return false;
      }
    }
  },
};
</script>