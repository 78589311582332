let uniqueIdCounter = 0;

export default {
  methods: {
    capitalizeFirstLetter(str) {
      if (!str) return '';
      let strArr = str.split(" ");
      for (let i = 0; i < strArr.length; i++) {
        strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substring(1);
      }
      return strArr.join(" ");
    },
    convertDate(date) {
      return new Date(date).toLocaleDateString("sv-SE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    studyPaceToIcon(studyPace, hasDifferentPace) {
      if (hasDifferentPace) return "25";
      var sp = studyPace;
      if(sp === null ) {
        return "25";
      } else if (isNaN) {
        var sp = parseInt(studyPace.replace("%", ""));
      }
      
      if (sp <= 25) {
        return "25";
      } else if (25 < sp && sp <= 50) {
        return "50";
      } else if (50 < sp && sp <= 75) {
        return "75";
      } else if (75 < sp && sp <= 100) {
        return "100";
      }
    },
    removeZeroButNotFive(str) {
      // Check if the string contains ",0" but not ",5"
      if (str === null) return;
      if (str.includes(",0") && !str.includes(",5")) {
        // Remove ",0" from the string
        str = str.replace(/,0/g, "");
      }
      return str;
    },
    getAllWordsButLastInText(text) {
      var textArray = text.split(" ");
      if (textArray.length > 1) {
        textArray.pop();
        return textArray.join(" ");
      } else {
        return text;
      }
    },
    getLastWordInText(text) {
      var textArray = text.split(" ");
      if (textArray.length > 0) {
        return textArray.pop();
      } else {
        return text;
      }
    },

    generateUniqueId(prefix = "id") {
      uniqueIdCounter++;
      return `${prefix}-${uniqueIdCounter}`;
    },
  },
};
