<template>
  <!-- Om mobil, eftersom det blir en ny sida så är det en a-tagg -->
  <a class="labour-forecast-mobile labor-link plausible-event-name=HOJ+arbmarkn+open+link"
    :href="this.labourForecastUrl" v-if="this.isMobile">
    <span class="labor-text">Arbetsmarknaden</span>
    <SvgIcon IconName="pil-hoger-ny" class="hide_in_table"></SvgIcon>
  </a>
  <!-- Om desktop, eftersom det öppnas en modal så är det en knapp -->
  <button class="btn labour-button plausible-event-name=HOJ+arbmarkn+open+modal" @click="openModal" v-else>
    Arbetsmarknaden
    <SvgIcon IconName="pil-hoger-ny" class="hide_in_table"></SvgIcon>
  </button>

  <div v-if="showModal" class="modal labour-forecast-modal" @keydown="handleKeydown">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Arbetsmarknaden</h1>
          <button type="button" class="close" aria-label="Stäng" @click="closeModal">
            &times;
          </button>
        </div>
        <div class="modal-body">

          <div class="etableringsgrad-container" v-if="this.labourForecastResult.Etableringsgrad">
            <h2 v-html="this.labourForecastResult.Etableringsgrad.Header"></h2>
            <div class="statistics-row">
              <div class="statistics-columns">
                <div class="statistics-col">
                  <div class="circle">{{ this.labourForecastResult.Etableringsgrad.EducationPercent }}</div>
                  <p>{{ this.labourForecastResult.Etableringsgrad.EducationName }}</p>
                </div>
                <div class="statistics-col">
                  <div class="circle">{{ this.labourForecastResult.Etableringsgrad.CountryPercent }}</div>
                  <p>Rikssnitt yrkesexamen</p>
                </div>
              </div>
              <div class="information-col">
                <div class="icon">
                  <SvgIcon IconName="information"></SvgIcon>
                </div>
                <div class="infotext" v-html="labourForecastResult.Etableringsgrad.DescriptionEducation">
                </div>
              </div>
            </div>
            <div class="information-link" v-if="labourForecastResult.Etableringsgrad.InformationLink">
              <a :href="this.labourForecastResult.Etableringsgrad.InformationLink.Uri"
                :target="this.labourForecastResult.Etableringsgrad.TargetInformationLink">
                <span>Mer information om etableringsgrad</span>
                <SvgIcon IconName="pil-hoger-ny"></SvgIcon>
              </a>
            </div>
          </div>

          <div id="labour-forecast" class="prognoses">
            <div class="ingress" v-html="labourForecastResult.LabourForecastsIngress"></div>
            <div v-if="this.haveForecasts">
              <div class="ingress" v-html="labourForecastResult.ExtraIngressLabourForecasts"></div>
              <!-- Rutor för varje prognos -->
              <div class="prognosis-list">
                <Prognosis v-for="item in labourForecastResult.LabourForecasts" :prognosis="item"
                  :hideSalaryStatistics="labourForecastResult.HideSalaryStatistics"
                  :missingPrognosisText="labourForecastResult.MissingForecast" />
              </div>
              <!-- end rutor -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="closeModal">Stäng</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../plugins/searchcompareservice";
import Prognosis from "./prognosis.vue";
import SvgIcon from "../../misc-components/svgicon.vue";
import { onMounted, onBeforeUnmount, ref, watch, nextTick } from "vue";

export default {
  name: "LabourForecast",
  props: {
    labourForecastUrl: {
      type: String,
      required: false,
    },
    eventId: {
      type: String,
      required: false,
    },
  },
  components: {
    Prognosis,
    SvgIcon,
  },
  setup(props) {
    // Create a URLSearchParams object from the URL
    let lfCode = "";
    let dCode = "";
    const showModal = ref(false);
    const haveForecasts = ref(false);
    const labourForecastResult = ref(null);
    let focusableElements = [];

    if (props.labourForecastUrl) {
      const urlSearchParams = new URLSearchParams(props.labourForecastUrl);
      const lfParam = urlSearchParams.get("lf");
      const dParam = urlSearchParams.get("d");
      lfCode = lfParam;
      dCode = dParam;
    } else {
      lfCode = props.eventId;
    }

    const openModal = () => {
      getLabourForecastData(lfCode, dCode);
    }

    const updateFocusableElements = (modal) => {
      focusableElements = Array.from(modal.querySelectorAll(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      ));
    };

    const handleKeydown = (event) => {
      if (event.key === 'Tab') {
        if (focusableElements.length === 0) return;

        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (event.shiftKey) {
          // If Shift+Tab and focus is on the first element, move to the last
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else {
          // If Tab and focus is on the last element, move to the first
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    const closeModal = () => {
      showModal.value = false;
    }

    const getLabourForecastData = (lf, d) => {
      api
        .getLabourForecastJson(lf, d, (error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })
        .then(
          (result) => {
            labourForecastResult.value = result.data;
            showModal.value = true;
            haveForecasts.value =
              labourForecastResult.value.LabourForecasts &&
              labourForecastResult.value.LabourForecasts.length > 0;
          },
          (reason) => {
            console.log("FEL: " + reason);
          }
        );
    };

    onMounted(() => {
      showModal.value = false;
      document.addEventListener('keydown', handleKeydown);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', handleKeydown);
    });

    watch(showModal, (newValue) => {
      let sitebody = document.body;
      if (newValue) {
        sitebody.classList.add("modal-open");
      } else {
        sitebody.classList.remove("modal-open");
      }

      nextTick(() => {
        let modal = document.querySelector('.labour-forecast-modal');
        if (modal) {
          updateFocusableElements(modal);
          if (focusableElements.length > 0) {
            focusableElements[0].focus();
          }
        }
      });
    });

    return {
      lfCode: lfCode,
      isMobile: window.innerWidth <= 991,
      header: "Arbetsmarknad",
      showModal: showModal,
      haveForecasts: haveForecasts,
      labourForecastResult: labourForecastResult,
      closeModal: closeModal,
      openModal: openModal
    };
  }
};
</script>
