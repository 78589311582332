<template>
  <td v-if="comparePropertyData?.length == 1" v-html="comparePropertyData[0]"></td>
  <td v-else-if="comparePropertyData?.length > 1" v-for="(data, index) in comparePropertyData">
    <span class="pre-event-label">{{ writeTillFalleText(index + 1) }}</span><br />
    <span v-html="data"></span>
  </td>
</template>
<script>
export default {
  name: "cellHtmlText",
  props: {
    rubrik: String,
    comparePropertyData: Array,
  },
  methods: {
    writeTillFalleText(eventNr) {
      return "Tillfälle " + eventNr;
    },
  }
};
</script>
