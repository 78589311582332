<template>
  <td>
    <p>
      <template v-if="procentData.length > 1">
        <ul>
          <li v-for="theProcent,index in this.procentData" class="list-unstyled">
            <span class="pre-event-label">{{  globalText.EventLabel +" " + (index +1) }}</span><br>
            <template v-if="theProcent !== undefined">
              <SvgIcon
                :IconName="IconName(theProcent)"
                :className="'icon procenticon icon-' + IconName(theProcent)"
                :Title=true
                TitleText="Studietakt"
              />
              <span class="procent"> {{ theProcent }}%</span>
            </template>
            <span v-else aria-label="Data saknas">{{ globalText.NA }}</span>
          </li>
        </ul>
      </template>

      <template v-else-if="procentData.length ===1 && procentData[0] !== undefined">

        <SvgIcon
                :IconName="IconName(procentData[0])"
                :className="'icon procenticon icon-' + IconName(procentData[0])"
                :Title=true
                TitleText="Studietakt"
              />
        <span class="procent"> {{ procentData[0] }}%</span>
      </template>
      <span v-else aria-label="Data saknas">{{ globalText.NA }}</span>
    </p>
  </td>
</template>
<script>
import { computed } from "vue";
import SvgIcon from "../../misc-components/svgicon.vue";
import { eduCompData } from "./compareData";

export default {
  name: "cellProcent",
  components: {
    SvgIcon,
  },
  props: {
    rubrik: String,
    comparePropertyData: Array, // kan bara vara string[]
  },
  methods: {
    IconName(procent) {
      if (procent < 25) {
        return 25;
      }
      return procent;
    },
  },
  setup() {
    const { eduCompareData } = eduCompData();
    return { globalText: eduCompareData.text };
  },

  data() {
    return {
      procentData: computed(() => this.comparePropertyData),
    };
  },
};
</script>
