/**
 * En uppmaning är att flytta ut funktionalitet som hör ihop till egna filer.
 *
 */

function openInPopup(event) {
	event.preventDefault();
	var href = $(this).attr("href");
	var url = document.URL;
	if (url.indexOf("?") >= 0) {
		url = encodeURIComponent(url);
		if (href.indexOf("twitter.com") >= 0) {
			var text = href.substring(href.indexOf("text="));
			href = "https://twitter.com/intent/tweet?url=" + url + "&amp;" + text;
		} else if (href.indexOf("linkedin.com") >= 0) {
			var title = href.substring(href.indexOf("title="));
			href =
				"https://www.linkedin.com/shareArticle?mini=true&amp;url=" +
				url +
				"&amp;" +
				title;
		} else if (href.indexOf("facebook.com") >= 0) {
			href =
				"https://www.facebook.com/sharer/sharer.php?u=" +
				url +
				"&amp;redirect_uri=" +
				url;
		}
	}
	window.open(href, "popup", "width=600, height=600");
}

function openMail(event) {
	event.preventDefault();
	var href = $(this).attr("href");
	var url = document.URL;
	if (url.indexOf("?") >= 0) {
		url = encodeURIComponent(url);
		href = "mailto:?subject=Tips%20fr%C3%A5n%20Studera.nu&body=" + url;
	}
	window.location.href = href;
}

function stripText(element) {
	var item = element[0];
	var newElement = $("<" + item.nodeName + "/>");
	for (i = 0; i < item.attributes.length; i++) {
		newElement.attr(item.attributes[i].name, item.attributes[i].value);
	}
	element.children().each(function () {
		newElement.append(this);
	});
	element.replaceWith(newElement);
	return newElement;
}

function ChangeXFormsHTML() {
	// Change selects/dropdowns
	$("#id_matrix select").wrap('<div class="styled-select"></div>');
	$("#id_matrix select").before('<span><span class="caret"></span></span>');

	// Change buttons
	$("#id_matrix input[type='submit']").addClass("regularButton");

	// Hide hogskoledropdown
	var $hogskoleDropdown = $("#id_matrix select[id*=HogskoleDropdown]");
	var hogskoleDropdownRow = $hogskoleDropdown.parents("tr:first");
	var hogskoleDropdownInfoTextRow = hogskoleDropdownRow.prev("tr:first");
	HideHogskoleDropdown(hogskoleDropdownRow, hogskoleDropdownInfoTextRow);

	var $personnrInput = $("#id_matrix input[id*=Personnummer]");
	var $securitynrInput = $("#id_matrix input[id*=SecurityNumber]");
	var personnrInputRow;
	if ($personnrInput.length) {
		personnrInputRow = $personnrInput.parents("tr:first");
	} else {
		personnrInputRow = $securitynrInput.parents("tr:first");
	}

	$("#id_matrix select[id*=ArendeDropdown]").change(function () {
		var selectedArendeText = $(
			"#id_matrix select[id*=ArendeDropdown] option:selected"
		).text();
		if (
			selectedArendeText === "Högskoleprovet" ||
			selectedArendeText === "Swedish Scholastic Aptitude Test"
		) {
			ShowHogskoleDropdown(hogskoleDropdownRow, hogskoleDropdownInfoTextRow);
			personnrInputRow.hide();
			DisableSubmitButton($("#id_matrix input[type='submit']"));
		} else if (
			selectedArendeText === "Antagning, meriter och urval" ||
			selectedArendeText === "Admission and applications"
		) {
			$hogskoleDropdown.val("-1");
			HideHogskoleDropdown(hogskoleDropdownRow, hogskoleDropdownInfoTextRow);
			personnrInputRow.show();
			EnableSubmitButton($("#id_matrix input[type='submit']"));
		} else {
			$hogskoleDropdown.val("-1");
			HideHogskoleDropdown(hogskoleDropdownRow, hogskoleDropdownInfoTextRow);
			personnrInputRow.hide();
			EnableSubmitButton($("#id_matrix input[type='submit']"));
		}
	});

	$("#id_matrix select[id*=HogskoleDropdown]").change(function () {
		var selected = $("#id_matrix select[id*=HogskoleDropdown]").val();
		if (selected !== "-1") {
			EnableSubmitButton($("#id_matrix input[type='submit']"));
		} else {
			DisableSubmitButton($("#id_matrix input[type='submit']"));
		}
	});

	$('.xform input[type="submit"]').click(function () {
		var validatorElement = $(".xformvalidator:visible:first");
		var inputElement = validatorElement.prevAll("input:first");

		$(window).scrollTop(inputElement.offset().top - 25);
		inputElement.focus();
	});
}

function EnableSubmitButton(submitButton) {
	submitButton.removeAttr("disabled");
	submitButton.removeClass("inActive");
}

function DisableSubmitButton(submitButton) {
	submitButton.attr("disabled", "disabled");
	submitButton.addClass("inActive");
}

function SetMainMenuBootstrapColumnClasses() {
	var topMenus = $("#mainnavigation li.dropdown > ul.dropdown-menu");

	$(topMenus).each(function () {
		var underMenus = $(this).find("div.col-md-3");

		underMenus.each(function () {
			var correctBootstrapClass = "col-md-4";
			var correctBoostrapClassNumber = Math.floor(12 / underMenus.length);

			if (correctBoostrapClassNumber < 4) {
				correctBootstrapClass = "col-md-" + correctBoostrapClassNumber;
			}

			$(this).removeClass("col-md-3");
			$(this).addClass(correctBootstrapClass);
		});
	});
}

function ShowHogskoleDropdown(
	hogskoleDropdownRow,
	hogskoleDropdownInfoTextRow
) {
	hogskoleDropdownRow.show();
	hogskoleDropdownInfoTextRow.show();
}

function HideHogskoleDropdown(
	hogskoleDropdownRow,
	hogskoleDropdownInfoTextRow
) {
	hogskoleDropdownRow.hide();
	hogskoleDropdownInfoTextRow.hide();
}

function RightAlignNonLatinLanguagePages() {
	if ($("html").attr("lang") == $("#HiddenFieldNonLatinLanguageCode").val()) {
		var page = $(".articlePage");

		if (page) {
			page.find("#ingress, #header, #mainBody").attr("dir", "rtl");
		}
	}
}

function setNewQuestionText() {
	var $buttons = $(".guide_navigation").find("button");
	var $accessTextChange = $("#guideStepAccess");
	$buttons.on("click", function () {
		$accessTextChange.text("Ny fråga visas.");
	});
}

// focus är deprecated så disablad CÅ
// function updateStepAria() {
// 	//UpdateAria/focus
// 	var actualStepHeader = $(
// 		"#guidePage .guide_step_container .header-container h2"
// 	);
// 	if (actualStepHeader != null || actualStepHeader != undefined) {
// 		//$("#guidePageLive").text(actualStepHeader.text());
// 		var slowly = actualStepHeader.focus();
// 	}
// }

/**
 * Globalt objekt att använda som namespace för ny funktionalitet
 */
var StuderaNu = {
	StickyElements: [],
	IsInfoWindowCallback: false,
	ToggleOpenText: "Visa",
	ToggleCloseText: "Dölj",
	ToggleNum: 6,
};

StuderaNu.throttle = function (fn, wait) {
	var time = Date.now();
	return function () {
		if (time + wait - Date.now() < 0) {
			fn();
			time = Date.now();
		}
	};
};
StuderaNu.debounce = function (func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
StuderaNu.resizeText = function ($element, settings) {
	// hämta bredden på containern
	var width = $element.width();
	var winWidth = $(window).width();
	//settings skall vara en array med element i containern
	for (var i = 0; i < settings.length; i++) {
		var setting = settings[i];
		// sätt defaultvärde
		if (typeof setting.factor == "undefined") {
			setting.factor = 5;
		}
		var fontSize = (width / setting.factor) | 0;

		if (typeof setting.min != "undefined" && setting.min > fontSize) {
			fontSize = setting.min;
		}
		if (winWidth <= 640) {
			$element.find(setting.selector).css({ fontSize: null, lineHeight: null });
		} else {
			$element
				.find(setting.selector)
				.css({ fontSize: fontSize + "px", lineHeight: fontSize + "px" });
		}
		// Set fontsize to new size

		//$element[0].style.fontSize = (width / factor | 0) + 'rem';
	}
};
StuderaNu.initResizeText = function ($element, settings, wait) {
	$(document).ready(function () {
		window.addEventListener(
			"resize",
			StuderaNu.throttle(function () {
				StuderaNu.resizeText($element, settings);
			}, wait)
		);
		StuderaNu.resizeText($element, settings);
	});
};
StuderaNu.initSticky = function (selector, wait) {
	$(document).ready(function () {
		var selectorArr = selector.split(",");
		for (var i = 0; i < selectorArr.length; i++) {
			var el = $(selectorArr[i]);
			var sticky = {
				selector: selectorArr[i],
				element: el,
				offsetTop: 0,
				offsetLeft: 0,
				container: el.closest(".sticky_container"),
				//offsetRight: 0,
				stickyMin: el.data("stickymin"),
				stickyMinLeft: el.data("stickyminleft"),
				alignRight: el.hasClass("align_right"),
			};
			StuderaNu.StickyElements.push(sticky);
		}
		window.addEventListener(
			"resize",
			StuderaNu.throttle(StuderaNu.checkSticky, wait)
		);
		window.addEventListener(
			"scroll",
			StuderaNu.throttle(StuderaNu.checkSticky, wait)
		);
		StuderaNu.checkSticky();
	});
};
StuderaNu.checkSticky = function () {
	var winWidth = $(window).outerWidth();
	var scrollPos = $(window).scrollTop();
	for (var i = 0; i < StuderaNu.StickyElements.length; i++) {
		var sticky = StuderaNu.StickyElements[i];

		if (sticky.stickyMin && winWidth < sticky.stickyMin) {
			sticky.element.removeClass("sticky");
			sticky.element.css("left", "");
			sticky.element.css("right", "");
		} else {
			if (!sticky.element.hasClass("sticky")) {
				sticky.offsetTop = sticky.element.offset().top;

				if (sticky.alignRight) {
					sticky.offsetLeft =
						sticky.container.offset().left +
						sticky.container.outerWidth() +
						30 -
						sticky.element.outerWidth();
				} else {
					sticky.offsetLeft = sticky.element.offset().left;
				}
			}
			if (scrollPos + 50 >= sticky.offsetTop) {
				if (!sticky.element.hasClass("sticky")) {
					sticky.element.addClass("sticky");
				}

				if (sticky.alignRight) {
					sticky.offsetLeft =
						sticky.container.offset().left +
						sticky.container.outerWidth() +
						30 -
						sticky.element.outerWidth();
				} else {
					sticky.offsetLeft = sticky.container.offset().left;
				}
				if (sticky.stickyMinLeft && winWidth < sticky.stickyMinLeft) {
					//sticky.element.css("left", "");
					sticky.element.css("left", sticky.offsetLeft);
					sticky.element.css("right", sticky.offsetLeft);
				}
				if (winWidth < 768) {
					sticky.element.css("left", "");
					sticky.element.css("right", "");
				} else {
					sticky.element.css("left", sticky.offsetLeft);
					sticky.element.css("right", sticky.offsetLeft);
				}
			} else {
				sticky.element.removeClass("sticky");
				sticky.element.css("left", "");
				sticky.element.css("right", "");
			}
		}
	}
};
StuderaNu.changeTextToogleBtn = function () {
	//var btn = $("button.see-more-details").on("click", function (e) {
	//	var btn_text = $(this).children("span:first-child");
	//	if ($(this).hasClass("collapsed")){
	//		btn_text.text("Dölj detaljer");
	//	} else {
	//		btn_text.text("Se detaljer");
	//	}
	//});
};
StuderaNu.activeStickyNav = function () {
	var stickyNavItem = $("#anchor_menu").find("li");
	$(stickyNavItem[0]).addClass("active");
	var list = [];
	for (var i = 0; i < stickyNavItem.length; i++) {
		var item = $(stickyNavItem[i]).find("a");
		var $id = $($(item).attr("href"));
		if ($id.length) {
			list.push($id.offset());
		} else {
			stickyNavItem.splice(i, 1);
		}
	}
	list.reverse();
	window.addEventListener(
		"scroll",
		StuderaNu.throttle(function () {
			StuderaNu.setActiveSticky(stickyNavItem, list);
		}, 30)
	);
	StuderaNu.setActiveSticky(stickyNavItem, list);
};
StuderaNu.setActiveSticky = function (stickyNavItem, list) {
	var scrolled = $(window).scrollTop();
	var active = true;

	//return $(window).scrollTop();
	for (var i = 0; i < list.length; i++) {
		if (scrolled >= list[i].top - 55 && active) {
			$(stickyNavItem[list.length - 1 - i])
				.siblings()
				.removeClass("active");
			$(stickyNavItem[list.length - 1 - i]).addClass("active");
			active = false;
		}
	}
};
StuderaNu.readMoreBtn = function () {
	var btn = $(".accordion-btn").on("click", function (e) {
		var clickedBtn = $(this);

		clickedBtn.siblings("button").removeClass("hidden");
		clickedBtn.addClass(" hidden");
	});
};
StuderaNu.toggleBtn = function () {
	var btn = $(".toggle_button").on("click", function (e) {
		var winWidth = $(window).innerWidth();
		activeBtn = $(this);
		var child = activeBtn.siblings("article");
		var parent = activeBtn.closest("[role='presentation']");

		//remove active from all information tabs
		$(".information_tabs_tab").removeClass("open");
		//remove active from all buttons
		$("[role='presentation']").removeClass("open");
		if (activeBtn.attr("aria-expanded") !== "true" || winWidth > 768) {
			$(".toggle_button").attr("aria-expanded", false);

			activeBtn.attr("aria-expanded", true);
			child.addClass("open");
			parent.addClass("open");
			if (winWidth < 768) {
				var scroll = $(activeBtn).offset().top;
				window.scroll({
					top: scroll - 50,
					behavior: "smooth",
				});
			}
		} else {
			activeBtn.attr("aria-expanded", false);
		}
	});
};
StuderaNu.updateUrl = function () {
	if (window.history && window.history.pushState) {
		var urlToUpdate = $(".updateUrl").data("updateUrl");
		if (urlToUpdate) {
			//console.debug("urlToUpdate: " + urlToUpdate); /*FUNKAR INTE I IE10, SLÅ EJ PÅ*/
			history.replaceState(urlToUpdate, null, urlToUpdate);
		}
	}
};
StuderaNu.GuideStepNavigated = function (data) {
	StuderaNu.updateUrl();
	StuderaNu.CheckGuideStepStateMulti();
	StuderaNu.CheckFoldable();
	if (!StuderaNu.IsInfoWindowCallback) {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}
	StuderaNu.IsInfoWindowCallback = false;
	//updateStepAria();
	setNewQuestionText();
};
StuderaNu.CheckGuideStepStateMulti = function () {
	var any = $(
		".guide_step_container.multi input[type=checkbox]:checked:not(.toggle-all)"
	).length;

	$(".guide_step_container.multi .navigation_alternative button").prop(
		"disabled",
		!any
	);
};
StuderaNu.InitGuide = function () {
	$(document.body).on(
		"change",
		".guide_step_container.multi input[type=checkbox]",
		function (e) {
			if ($(this).hasClass("toggle-all")) {
				$(this)
					.closest(".guide_step_container.multi")
					.find("input[type=checkbox]:not(.toggle-all)")
					.prop("checked", $(this).prop("checked"));
			}
			StuderaNu.CheckGuideStepStateMulti();
		}
	);
	$(document.body).on("click", ".info-backdrop", function () {
		$(this).closest(".outer-info").find("button[type=submit]").trigger("click");
	});
	$(document.body).on(
		"click",
		".main-info-close-link, .alternative-info-link, .main-info-link",
		function () {
			StuderaNu.IsInfoWindowCallback = true;
		}
	);

	StuderaNu.CheckGuideStepStateMulti();

	window.addEventListener(
		"resize",
		StuderaNu.debounce(function () {
			StuderaNu.CheckFoldable();
		}, 300)
	);
	StuderaNu.CheckFoldable();
};
StuderaNu.RowCompare = function (a, b) {
	if (a.top < b.top) return -1;
	if (a.top > b.top) return 1;
	return 0;
};
StuderaNu.CheckFoldable = function () {
	var rows = [];
	$(".alternatives.foldable").each(function () {
		var $foldable = $(this);
		$(".foldable-item", $foldable).each(function () {
			var $element = $(this);
			var top = $element.position().top;
			var existed = false;
			for (var i = 0; i < rows.length; i++) {
				if (Math.abs(rows[i].top - top) < 5) {
					existed = true;
					rows[i].elements.push($element);
					break;
				}
			}
			if (!existed) {
				var row = { top: top, elements: [$element] };
				StuderaNu.InsertSorted(rows, row, StuderaNu.RowCompare);
				//rows.push(row);
			}
		});
		if (rows.length > StuderaNu.ToggleNum) {
			//init foldable
			var $toggle_btn;
			var $toggle_btn_container;
			if ($foldable.siblings(".toggle-foldable").length) {
				//already initiated, check state
				$toggle_btn_container = $foldable.siblings(".toggle-foldable").first();
				$toggle_btn = $toggle_btn_container.find("button").first();
				if ($toggle_btn_container.hasClass("open")) {
					StuderaNu.ShowFolded(rows);
				} else {
					StuderaNu.HideFolded(rows);
				}
				$toggle_btn.off("click");
			} else {
				//do a fresh init
				$toggle_btn_container = $(
					'<div class="toggle-foldable clearfix"><button >' +
						StuderaNu.ToggleOpenText +
						'<span class="caret"></span></button></div>'
				);
				$foldable.after($toggle_btn_container);
				$toggle_btn = $toggle_btn_container.find("button").first();
				StuderaNu.HideFolded(rows);
			}
			$toggle_btn.on("click", function (e) {
				$toggle_btn_container.toggleClass("open");
				if ($toggle_btn_container.hasClass("open")) {
					StuderaNu.ShowFolded(rows);
					$toggle_btn.html(
						StuderaNu.ToggleCloseText + '<span class="caret"></span>'
					);
				} else {
					StuderaNu.HideFolded(rows);
					$toggle_btn.html(
						StuderaNu.ToggleOpenText + '<span class="caret"></span>'
					);
				}
				e.preventDefault();
				return false;
			});
		} else {
			//disable foldable
			$foldable.siblings(".toggle-foldable").remove();
			StuderaNu.ShowFolded(rows);
		}
	});
};
StuderaNu.ShowFolded = function (rows) {
	for (var i = 0; i < rows.length; i++) {
		for (var j = 0; j < rows[i].elements.length; j++) {
			rows[i].elements[j].show();
		}
	}
};
StuderaNu.HideFolded = function (rows) {
	for (var i = StuderaNu.ToggleNum; i < rows.length; i++) {
		for (var j = 0; j < rows[i].elements.length; j++) {
			rows[i].elements[j].hide();
		}
	}
};

//https://stackoverflow.com/questions/1344500/efficient-way-to-insert-a-number-into-a-sorted-array-of-numbers
StuderaNu.InsertSorted = function (arr, item, comparator) {
	if (comparator == null) {
		// emulate the default Array.sort() comparator
		comparator = function (a, b) {
			if (typeof a !== "string") a = String(a);
			if (typeof b !== "string") b = String(b);
			return a > b ? 1 : a < b ? -1 : 0;
		};
	}

	// get the index we need to insert the item at
	var min = 0;
	var max = arr.length;
	var index = Math.floor((min + max) / 2);
	while (max > min) {
		if (comparator(item, arr[index]) < 0) {
			max = index;
		} else {
			min = index + 1;
		}
		index = Math.floor((min + max) / 2);
	}

	// insert the item
	arr.splice(index, 0, item);
};

StuderaNu.initialize = function ($) {
	console.debug("initializing StuderaNu", $.fn.jquery);
	//fr onready ovan
	//QuizPage carousels
	$("#questionsCarousel").on("slid.bs.carousel", function () {
		$(".quizStartButton").hide();
		curSlide = $(".carousel-inner .item.active");
		var activeHeader = curSlide.find("h2");
		activeHeader.focus(); // Setting focus on actual header
		//Aria-live
		//var liveregion = $(this).find(".liveregion");
		//liveregion.text(activeHeader.text());
		if (curSlide.is(":first-child")) {
			$(".carousel-control.left").hide();
			$(".carousel-control.right").hide();
			$(".quizSubmitButton").hide();
			$(".quizStartButton").show();
			$("#resetQuizBtn").hide();
		} else if (curSlide.is(":last-child")) {
			$(".carousel-control.left").show();
			$(".carousel-control.right").hide();
			$(".quizSubmitButton").show();
			$(".quizStartButton").hide();
			$("#resetQuizBtn").show();
		} else {
			$(".carousel-control.left").show();
			$(".carousel-control.right").show();
			$(".quizSubmitButton").show();
			$(".quizStartButton").hide();
			$("#resetQuizBtn").show();
		}
		$("html, body").animate(
			{ scrollTop: $("#questionsCarousel").offset().top },
			500
		);
	});
	$("#resetQuizBtn").on("click", function () {
		$("#questionsCarousel").carousel(1);
	});
	/*
		Hela denna eventlistener kan städas bort om länkarna skrivs om så att det blir riktiga Ankarlänkar.
		Vad tabIndex -1 ska behövas på en div för, förstår jag inte.
	*/
	$("nav.skip-links a#gotonav").click(function () {
		$("#mainnavigation").attr("tabIndex", -1).focus();
	});
	$("nav.skip-links a#gotocontent").click(function () {
		$("#subpageTemplate").attr("tabIndex", -1).focus();
	});
	/*
		Behövs dessa fortfarande?
		verkar finnas logik för att bygga upp länkarna i backend.
		Det blir lite kaka på kaka och oklart vad som gäller om "samma" kod finns på två ställen.
		Möljligen kan popup funktionen behövas!
	*/
	$(".js-social-sharing").on("click", openInPopup);
	$(".js-mail-sharing").on("click", openMail);
	//fr onready ovan
	SetMainMenuBootstrapColumnClasses();
	ChangeXFormsHTML();
	RightAlignNonLatinLanguagePages();
	$(".navbar-nav>li").removeClass("noJavaScript");
	$("#languageSwitchMenu").removeClass("noJavaScript");
	$("#languageSwitchMenu-Mobile").removeClass("noJavaScript");
	$("#searchPage #searchResultHits").focus(); //Focus on hits
	$("#searchPage #normalResult ol .ess-documentInformation").each(function () {
		if ($(this).children().length > 0) {
			var node = $(this).children("dd");
			var docType = node.text();
			if (!(docType.indexOf("Powerpoint") === -1)) {
				node.addClass("ppt");
			} else if (!(docType.indexOf("Excel") === -1)) {
				node.addClass("xls");
			} else if (!(docType.indexOf("Word") === -1)) {
				node.addClass("doc");
			} else if (!(docType.indexOf("Pdf") === -1)) {
				node.addClass("pdf");
			} else {
				node.addClass("other");
			}
		}
	});
	$("#searchPage .header .menu ul li").each(function () {
		var newElement = stripText($(this));
	});
	var node = $("#searchPage .header h4:contains('hidden:sort:relevance')"); //Hack för att ta bort bugg från siteseeker som lägger på hidden:sort:relevance efter Inga träffar... och lägger istället till samma html som siteseeker gör i korrekta fall
	if (node.length > 0) {
		node.html(node.html().replace("hidden:sort:relevance", ""));
		node.append(
			"<span id='essi-wholesite-prep'> inom <strong id='essi-wholesite'>Studera.nu</strong></span>"
		);
	}
	/*Accessibilty on search options */
	$("body").on("DOMNodeInserted", ".autocomplete-suggestions", function () {
		$(".autocomplete-suggestions").attr("role", "listbox");
	});
	/*For search page and searchCompare page*/
	var ariaCountMessage = " sökförslag är tillgängliga";
	var ariaNavigateMessage =
		", använd upp och ned på piltangenterna för att navigera.";
	$("#SearchBox").on("keyup", function () {
		var autoSuggestion = $(
			"#autocomplete-suggestion-list .autocomplete-suggestion"
		);
		var helpText = $(".suggestions-help");
		autoSuggestion.attr("role", "options");
		var check = $(
			"#autocomplete-suggestion-list .autocomplete-suggestions"
		).css("display");
		if (check === "none") {
			helpText.text("Inga" + ariaCountMessage + ".");
		} else {
			var numOfSuggestion = autoSuggestion.length;
			helpText.text(numOfSuggestion + ariaCountMessage + ariaNavigateMessage);
		}
	});
	function searchSuggestionAccessibility(searchBox) {
		var search = searchBox;
		var autoSuggestion = $(
			"#" + search + "-autocomplete-suggestion-list .autocomplete-suggestion"
		);
		var helpText = $("." + search + "-suggestions-help");
		autoSuggestion.attr("role", "options");
		var visible =
			$(
				"#" + search + "-autocomplete-suggestion-list .autocomplete-suggestions"
			).css("display") === "none"
				? false
				: true;
		var check =
			$(
				"#" + search + "-autocomplete-suggestion-list .autocomplete-suggestions"
			).children.length > 0
				? true
				: false;
		if (visible) {
			if (!check) helpText.text("0" + ariaCountMessage + ".");
			else {
				var numOfSuggestion = autoSuggestion.length;
				helpText.text(numOfSuggestion + ariaCountMessage + ariaNavigateMessage);
			}
		} else helpText.text("Inga" + ariaCountMessage + ".");
	}
	$("#SearchBox1_QuickSearchBox").on("keyup", function (e) {
		var searchBox = e.target.id;
		searchSuggestionAccessibility(searchBox);
	});
	$("#SearchBox2_QuickSearchBox").on("keyup", function (e) {
		var searchBox = e.target.id;
		searchSuggestionAccessibility(searchBox);
	});
	$("#sokJamForSearch").on("keyup", function (e) {
		var searchBox = e.target.id;
		searchSuggestionAccessibility(searchBox);
	});
	//fr on-ready ovan.
	if ($(window).width() < 994 && $("#subNavigation").length) {
		$(".subNavigation").addClass("panel-collapse collapse");
	} else if ($(window).width() > 993 && $("#subNavigation").length) {
		$(".subNavigation").removeClass("panel-collapse collapse in");
		$(".subNavAccordion .panel-title a").addClass("collapsed");
	}
	if ($(window).width() < 994 && $("#subNavigation").length) {
		$(".subNavigation").addClass("panel-collapse collapse");
		$(".subNavAccordion .panel-title a").addClass("collapsed");
	}
	//OK HIT CÅ

	//smooth scroll
	$(".on_page_nav a").on("click", function (e) {
		var id = $(this).attr("href");
		var scroll = $(id).offset().top;
		$("html, body").animate({ scrollTop: scroll - 50 }, 500);
		e.preventDefault();
		return false;
	});
	StuderaNu.toggleBtn();
	StuderaNu.readMoreBtn();
	StuderaNu.changeTextToogleBtn();
	StuderaNu.activeStickyNav();
	StuderaNu.updateUrl();
	StuderaNu.InitGuide();
	//updateStepAria();
	setNewQuestionText();
	//Remove title from images without alt-text but not for svg
	var attr = $(".imagefullwidth").attr("alt");
	if (typeof attr !== typeof undefined && attr === "") {
		$("img").not("[data-src*='.svg']").removeAttr("title");
	}
};

export default StuderaNu;
