import {
  CellLinkModel,
  CellLinkDataGatherer,
  Education,
} from "./models";
type stringOrNumber = string | number;

export function rowDataUtilities() {
  const isLinkModelUnique = function (cellData: CellLinkModel[]): boolean {
    let allUrl = cellData.map((cd) => cd.url);
    let isUnique = [...new Set(allUrl)].length === 1;
    return isUnique;
  };

  /*
	RowData är en array av CellData (stringOrNumber[][])

	Celldata följer följande mönster:
	Om celldataarrayen innehåller samma värden så retuneras en array med bara det värdet.
	Om celldataarrayen innehåller olika värden så returneras arrayen som den är.
	Om celldataarrayen inte är en array utan en string eller number så returneras en array med bara det värdet.

        100,                  [100%],
        [100,25,100,100],     [tillfälle 1: 100%, tillfälle 2: 25%, tillfälle 3: 100%, tillfälle 4: 100%]
        [100,100,100],        [100%]
        [25,100]              [tillfälle 1: 25%, tillfälle 2: 100%]
        */
  const accommodateArray = function (
    rowData: any // (stringOrNumber|stringOrNumber[] | CellLinkModel|CellLinkModel[])[]
  ): (stringOrNumber[] | CellLinkModel[])[] {
    let returnArray: (stringOrNumber[] | CellLinkModel[])[] = [];

    // går igenom alla Education
    for (let i = 0; i < rowData.length; i++) {
      let celldata = rowData[i];
      if (Array.isArray(celldata) === false) {
        // gör om celldata som inte är en array till en array.
        returnArray[i] = [celldata];
      } else {
        // testar den inre arrayen om den innehåller samma värden.
        let isUnique: boolean;
        if (celldata[0] instanceof CellLinkModel) {
          isUnique = isLinkModelUnique(celldata);
        } else {
          isUnique = [...new Set(celldata)].length === 1;
        }
        if (isUnique) {
          returnArray[i] = [celldata[0]];
        } else {
          // det är en array låt den passera
          returnArray[i] = celldata;
        }
      }
    }
    return returnArray;
  };

  const uniqueArray = function (arr: stringOrNumber[]): stringOrNumber[] {
    if (typeof arr === "string" || typeof arr === "number") {
      return [arr];
    }

    let uniqueSet: Set<stringOrNumber> = new Set(arr);

    if (uniqueSet.size > 1) {
      let uniqueArr: stringOrNumber[] = [...uniqueSet];
      uniqueArr.sort();
      return uniqueArr;
    } else if (uniqueSet.size === 1) {
      return [...uniqueSet];
    }
    return ["Ingen data"];
  };



  return {
    uniqueArray: uniqueArray,
    accommodateArray: accommodateArray,
  };
}
