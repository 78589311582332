<template>
    <div class="underline-accordion">
        <div class="underline-accordion__header">
            <button type="button" data-toggle="collapse" :data-target="`#${this.uniqueId}-underline-collapse`"
                aria-expanded="false" :aria-controls="`${this.uniqueId}-underline-collapse`"
                class="underline-accordion__toggle">
                <h5>
                    {{ heading }}
                </h5>
                <SvgIcon IconName="triangel-pil-ned" Title=true TitleText="" aria-hidden="true"></SvgIcon>
            </button>
        </div>
        <div class="underline-accordion__content collapse" :id="`${this.uniqueId}-underline-collapse`">
            <div v-if="isHtml" v-html="text"></div>
            <div v-else> {{ text }}</div>
        </div>
    </div>
</template>

<script>
import SvgIcon from "../misc-components/svgicon.vue";
export default {
    name: "UnderLineAccordion",
    components: {
        SvgIcon,
    },
    props: {
        heading: String,
        text: String,
        isHtml: Boolean
    },
    data() {
        return {
            uniqueId: null,
        }
    },

    mounted() {
        this.uniqueId = this.generateUniqueId();
    },
};
</script>