/** 
 **********  
 * Cookies,  
 **********  
 **/
var studera = window.studera || {};
studera.CookieConsent = function ($) {

    /* ********** Privata variabler  ********************   */
    var $cookieMessage,
        $cookieToggle,
        $cookieName,
        $cookieValue,
        $cookieExpireDate;
    /* ********** Exponerade variabler  ********************   */

    /* ********** Privata funktioner     */

    function hideCookieMessage() {
        $cookieMessage.addClass("hidden");
        $cookieExpireDate.setTime($cookieExpireDate.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = $cookieName + "=" + $cookieValue + ";expires=" + $cookieExpireDate.toGMTString() + ";path=/";
    }

    /* ********** Exponerade funktioner     */

    function initCookieConsentHandlers() {
        $cookieMessage = $(".js-cookie-message");
        $cookieToggle = $(".js-cookie-message-toggle");
        $cookieName = "StuderaAcceptCookieUsage";
        $cookieValue = "agree";
        $cookieExpireDate = new Date();

        if (document.cookie.indexOf($cookieName) !== -1) {
            $cookieMessage.addClass("hidden");
        } else {
            $cookieMessage.removeClass("hidden");
            $cookieToggle.on("click", hideCookieMessage);
        }
    }

    /* ********** Returnera exponerat gränssnitt     */

    return {
        init: initCookieConsentHandlers
    }
}(jQuery);

jQuery(studera.CookieConsent.init);