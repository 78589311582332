//Script global variables
var map, layer;
var zoomPosition;
var infowindow;
var allCampus = [];
var stockholm = undefined;

export default function swedishinstitutionsmap() {
	//only init scripts if we're in swedishinstitutionpage. else just return.
	if ($("#swedishinstitutionmappage").length < 1) {
		return;
	}

	waitForGoogleScriptToLoad(3000).then(() => {
		//load page function dependent on google.maps
		stockholm = new google.maps.LatLng(63.8380201, 20.2480042);
		initGoogleMap();
		injectLeftMenu();
		initHandlers();
	});
}

function waitForGoogleScriptToLoad(timeout) {
	var start = Date.now();
	return new Promise(waitForGoogle); // set the promise object within the ensureFooIsSet object

	// waitForGoogle makes the decision whether the condition is met
	// or not met or the timeout has been exceeded which means
	// this promise will be rejected
	function waitForGoogle(resolve, reject) {
		if (typeof google !== "undefined" && typeof google.maps !== "undefined") {
			//console.log('!Loaded, countinuing')
			resolve(google.maps);
		} else if (timeout && Date.now() - start >= timeout)
			reject(new Error("Google maps script not loaded"));
		else {
			//console.log('not loaded yet, waiting some more')
			setTimeout(waitForGoogle.bind(this, resolve, reject), 30);
		}
	}
}

function initGoogleMap(event) {
	map = new google.maps.Map(document.getElementById("map-canvas"), {
		center: stockholm,
		zoom: 4,
	});
	map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
	addZoomChangedListener();
	RenderAllCampus();

	var viewAllControlDiv = document.createElement("div");

	viewAllControlDiv.index = 1;
	map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(viewAllControlDiv);
}

function removeUrlParameter(url, name) {
	var urlparts = url.split("?");

	if (urlparts.length >= 2) {
		var urlBase = urlparts.shift();
		var queryString = urlparts.join("?");

		var prefix = encodeURIComponent(name) + "=";
		var pars = queryString.split(/[&;]/g);
		for (var i = pars.length; i-- > 0; )
			if (pars[i].lastIndexOf(prefix, 0) !== -1) pars.splice(i, 1);
		url = urlBase + "?" + pars.join("&");

		if (url.substring(url.length - 1) == "?") {
			url = url.substring(0, url.length - 1);
		}
	}

	return url;
}

function addUrlParameter(url, name, val) {
	// If the parameter is present but new value is empty then remove parameter
	//else if (val.length == 0 || val == -1 || val == 1) {
	if (val.length == 0 || val == -1) {
		return removeUrlParameter(url, name);
	}

	//Try to replace the parameter if it's present in the url
	var count = 0;
	url = url.replace(
		new RegExp("([\\?&]" + name + "=)[^&]+"),
		function (a, match) {
			count = 1;
			return match + encodeURIComponent(val);
		}
	);

	//If the parameter is not present in the url append it
	//if (!count && val.length > 0) {
	if (!count && val != undefined) {
		url +=
			(url.indexOf("?") >= 0 ? "&" : "?") +
			name +
			"=" +
			encodeURIComponent(val);
	}
	return url;
}

function pushUpdateURL(url) {
	if (url != window.location && history.pushState != null) {
		window.history.pushState(url, "", url);
	}
}

function pushUpdateParameter(parameter, value) {
	var filteredURL = addUrlParameter(window.location.href, parameter, value);
	pushUpdateURL(filteredURL);
}

function initHandlers() {
	$("#map-canvas").on("click", "a[rel='tab']", function () {
		pushUpdateParameter(
			"id",
			$(this).attr("href").substring(4, $(this).attr("href").length)
		);
		clearInstitutionPageData();
		RenderSwedishInstitutionPage();
		return false;
	});

	$("#close-info-button").on("click", function () {
		clearInstitutionPageData();
		$(".result-container").hide();
		var clean_uri =
			location.protocol + "//" + location.host + location.pathname;
		window.history.replaceState({}, document.title, clean_uri);
		return false;
	});

	$(document).on("keyup", function (e) {
		if (e.key === "Escape") {
			clearInstitutionPageData();
			$(".result-container").hide();
			var clean_uri =
				location.protocol + "//" + location.host + location.pathname;
			window.history.replaceState({}, document.title, clean_uri);
			return false;
		}
	});

	$(document).on("keydown", function (e) {
		let isTabPressed = e.key === "Tab" || e.keyCode === 9;

		if (!isTabPressed) {
			return;
		}
		const desktop = window.matchMedia("(min-width: 768px)");

		if (!desktop.matches) {
			const focusableElements =
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
			const modal = $("#standardPage"); // select the modal by its id

			const firstFocusableElement = modal.find(focusableElements).first(); // get first element to be focused inside modal
			const focusableContent = modal.find(focusableElements);
			const lastFocusableElement = focusableContent.last(); // get last element to be focused inside modal

			if (e.shiftKey) {
				// if shift key pressed for shift + tab combination
				if (document.activeElement === firstFocusableElement[0]) {
					lastFocusableElement.focus(); // add focus for the last focusable element
					e.preventDefault();
				}
			} else {
				// if tab key is pressed
				if (document.activeElement === lastFocusableElement[0]) {
					// if focused has reached to last focusable element then focus first focusable element after pressing tab
					firstFocusableElement.focus(); // add focus for the first focusable element
					e.preventDefault();
				}
			}
		}
	});

	$(".swedishinstitutionmappage-subNavigation").on(
		"click",
		"a[rel='tab']",
		function () {
			pushUpdateParameter("id", $(this).attr("id"));
			clearInstitutionPageData();
			RenderSwedishInstitutionPage();
			$('a[data-parent="#accordion"]').trigger("click");

			const desktop = window.matchMedia("(min-width: 768px)");

			if (desktop.matches) {
				$([document.documentElement, document.body]).animate(
					{
						scrollTop: $("#map-canvas").offset().top + -10,
					},
					0
				);
			}
			$("#close-info-button").focus();

			return false;
		}
	);

	$("#SearchBox").on("keyup change", function () {
		var value = $(this).val().toLowerCase();
		$(".institution-list > li").each(function () {
			if ($(this).text().toLowerCase().search(value) > -1) {
				$(this).show();
			} else {
				$(this).hide();
			}
		});
	});

	$(".searchbutton").on("click", function () {
		var value = $("#SearchBox").val().toLowerCase();
		$(".institution-list > li").each(function () {
			if ($(this).text().toLowerCase().search(value) > -1) {
				$(this).show();
			} else {
				$(this).hide();
			}
		});
	});
}

/**
 * Left Menu functions
 */

function injectLeftMenu() {
	$("li.level1 a").each(function () {
		var $link = $(this);

		//Get the original URL,http://localhost:17003/sa-funkar-hogskolan/svenska-larosaten/adsdsad/
		var originalUrl = $link.attr("href");
		var the_arr = originalUrl.split("/");
		the_arr.pop();
		the_arr.pop();
		//Replace the last part of the URL and add id (short address) parameter
		var id = $link.attr("id");
		var newUrl = the_arr.join("/") + "/?id=" + id;
		$link.attr("rel", "tab");
		$link.attr("href", newUrl);
	});
}

function changeLeftNavigation() {
	$(".swedishinstitutionmappage-subNavigation")
		.find(".active")
		.removeClass("active");
	var institutionId = GetURLParameter("id");

	if (
		institutionId !== undefined &&
		institutionId != "" &&
		institutionId.length != 0
	) {
		$("#" + institutionId)
			.parent()
			.addClass("active");
	}
}
/**
 * End Left Menu functions
 */

function clearInstitutionPageData() {
	$("#institutionPageHeader").empty();
	$("#mainBodyContent").empty();
	$(".swedishinstitutionmappage-subNavigation")
		.find(".active")
		.removeClass("active");
}

function RenderAllCampus() {
	$.ajax({
		type: "POST",
		url: "/SwedishInstitutionMap/GetAllSwedishInstitutionCampus",
		contentType: "application/json; charset=utf-8",
		data: JSON.stringify({
			url: window.location.href,
		}),
		dataType: "json",
		success: SuccessGetAllCampus,
		error: function (xhr, status, error) {
			console.log("RenderAllCampus Error");
			console.log("RenderAllCampus xhr: " + xhr.status + xhr.responseText);
			console.log("RenderAllCampus status: " + status);
			console.log("RenderAllCampus error: " + error);
		},
	});

	return false;
}

function campus(
	marker,
	institutionName,
	institutionId,
	campusName,
	campusAddress,
	campusTel,
	campusEmail,
	campusWeb
) {
	this.marker = marker;
	this.institutionName = institutionName;
	this.institutionId = institutionId;
	this.campusName = campusName;
	this.campusAddress = campusAddress;
	this.campusTel = campusTel;
	this.campusEmail = campusEmail;
	this.campusWeb = campusWeb;
}

function SuccessGetAllCampus(msg) {
	$.each(msg, function (index, item) {
		var latlong = item.CampusCoordinates.split(",");
		var pos = new google.maps.LatLng(latlong[0], latlong[1]);
		var marker = new google.maps.Marker({
			position: pos,
			map: map,
			animation: google.maps.Animation.DROP,
			title: item.Header,
		});

		// Lärosäte + address should be displayed in the map infowindow

		var contentString = "";

		if (item.CampusName != null) {
			contentString =
				'<div id="content">' +
				'<a href="?id=' +
				item.InstitutionId +
				'" rel="tab">' +
				item.InstitutionName +
				"</a>" +
				"<div>" +
				item.CampusName +
				"</div>" +
				item.CampusAddress +
				"</div>";
		} else {
			contentString =
				'<div id="content">' +
				'<a href="?id=' +
				item.InstitutionId +
				'" rel="tab">' +
				item.InstitutionName +
				"</a>" +
				item.CampusAddress +
				"</div>";
		}

		google.maps.event.addListener(marker, "click", function () {
			if (infowindow) infowindow.close();
			infowindow = new google.maps.InfoWindow({
				content: contentString,
			});
			infowindow.open(map, marker);
		});

		// Save all campus to be used later
		allCampus.push(
			new campus(
				marker,
				item.InstitutionName,
				item.InstitutionId,
				item.CampusName,
				item.CampusAddress,
				item.CampusTel,
				item.CampusEmail,
				item.CampusWeb
			)
		);
	});
	RenderSwedishInstitutionPage(); //we need to get all campus data before rendering markers
}

// Sets the map on all markers in the array.
function setAllMap(map) {
	for (var i = 0; i < allCampus.length; i++) {
		allCampus[i].marker.setMap(map);
	}
}

// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
	setAllMap(null);
}

// Shows any markers currently in the array.
function showMarkers() {
	setAllMap(map);
	map.setZoom(4);
	map.setCenter(stockholm);
	map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
}

// Shows selected campus markers
function zoomCampusMarkers(institutionId) {
	map.setOptions({ minZoom: 4, maxZoom: 16 });
	var bounds = new google.maps.LatLngBounds();
	for (var i = 0; i < allCampus.length; i++) {
		if (allCampus[i].institutionId == institutionId) {
			allCampus[i].marker.setMap(map);
			bounds.extend(allCampus[i].marker.getPosition());
		}
	}
	map.setMapTypeId(google.maps.MapTypeId.HYBRID);
	map.fitBounds(bounds);
}

function GetURLParameter(sParam) {
	var sPageURL = window.location.search.substring(1);
	var sURLVariables = sPageURL.split("&");
	for (var i = 0; i < sURLVariables.length; i++) {
		var sParameterName = sURLVariables[i].split("=");
		if (sParameterName[0] == sParam) {
			return sParameterName[1];
		}
	}
}

function RenderSwedishInstitutionPage() {
	var institutionId = GetURLParameter("id");

	if (
		institutionId !== undefined &&
		institutionId != "" &&
		institutionId.length != 0
	) {
		clearMarkers();
		zoomCampusMarkers(institutionId);
		if (
			infowindow !== undefined &&
			infowindow != "" &&
			infowindow.length != 0
		) {
			infowindow.close();
		}
		changeLeftNavigation();
		var institutionName = GetInstitutionName(institutionId);
		RenderBlocks(institutionId);
		$("#institutionPageHeader").html("<h3>" + institutionName + "</h3>");
		$(".result-container").show();
	} else {
		$(".result-container").hide();
	}
}

function GetInstitutionName(id) {
	for (var i = 0; i < allCampus.length; i++) {
		if (allCampus[i].institutionId == id) {
			return allCampus[i].institutionName;
		}
	}
	return null;
}

function loadDiv(shortUrl, divToFind, divToReplace) {
	$.get(shortUrl, function (html) {
		$(divToReplace).html($(html).find(divToFind));
	});
}

function RenderBlocks(institutionId) {
	var shorturl = "/" + institutionId;
	loadDiv(shorturl, "#campusblockarea", "#mainBodyContent");
	$("#mainBodyContent").removeClass("col-xs-6 col-md-8");
}

function addZoomChangedListener() {
	google.maps.event.addListener(map, "zoom_changed", function () {});
}

/**
 * The viewAllControl adds a control to the map that view all markers on the map
 * This constructor takes the control DIV as an argument.
 */
function ViewAllControl(controlDiv, map) {
	// Set CSS for the control border

	var controlUI = document.createElement("div");
	controlUI.className = "map-button";
	controlUI.title = "Klicka för att visa alla svenska campus";
	controlDiv.appendChild(controlUI);

	// Set CSS for the control interior
	var controlText = document.createElement("div");
	controlText.innerHTML = "Visa alla";
	controlUI.appendChild(controlText);

	// Setup the click event listeners: simply set the map to
	// Chicago
	google.maps.event.addDomListener(controlUI, "click", function () {
		showMarkers();
		clearInstitutionPageData();
		if (
			infowindow !== undefined &&
			infowindow != "" &&
			infowindow.length != 0
		) {
			infowindow.close();
		}
	});
}
