<template>
  <template v-if="this.linkDatas.length > 1">
    <td>
      <ul class="list-unstyled">
        <li v-for="(linkdata, index) in linkDatas">
          <span class="pre-event-label">{{ writeTillFalleText(index + 1) }}</span><br />
          <SvgIcon v-if="haveIcon(index)" :IconName="linkdata.iconName" :Title="false"></SvgIcon>
          <a :href="linkdata.url" target="_blank">
            <span>{{ writeText(index) }}</span>
            <SvgIcon IconName="extern-lank"></SvgIcon>
          </a>
        </li>
      </ul>
    </td>
  </template>
  <td v-else-if="this.linkDatas.length == 1">
    <p>
      <SvgIcon v-if="haveIcon(0)" :IconName="this.linkDatas[0].iconName" :Title="false"></SvgIcon>
      <a :href="this.linkDatas[0].url" target="_blank">
        <span>{{ writeText(0) }}</span>
        <SvgIcon IconName="extern-lank" TitleText="länken öppnas i ett nytt fönster" :Title="true"></SvgIcon>
      </a>
    </p>

  </td>
  <td v-else>
    <p>no info</p>
  </td>
</template>

<script>
import { rowDataUtilities } from './RowDataUtilities';
import { computed } from "vue";
import SvgIcon from "../../misc-components/svgicon.vue";

const combinedTitle = "Alla tillfällen";

export default {
  name: "CellLink",

  props: {
    comparePropertyData: Object, // array of CellLinkModel => {iconName: string|undefinded, linkUrl: string, linkText: string}
    rubrik: String,
    edunumber: Number,
    combine: Boolean
  },
  components: {
    SvgIcon
  },
  methods: {
    haveIcon(index) {
      let iconName = this.linkDatas[index].iconName;
      return iconName != null && iconName != undefined && iconName.length > 0;
    },

    writeTillFalleText(eventNr) {
      //index
      return "Tillfälle " + eventNr;
    },
    writeText(index) {
      if (this.linkDatas[index].text?.length > 0 ?? false) {
        return this.linkDatas[index].text;
      } else {
        return combinedTitle;
      }
    },
  },
  setup(props) {
  },
  data() {
    return {
      linkDatas: computed(() => {
        if (this.combine) {
          const { uniqueArray } = rowDataUtilities();
          var uniqueUrlss = uniqueArray(this.comparePropertyData?.map(e => e.url));
          if (uniqueUrlss.length == 1) {
            let first = this.comparePropertyData[0];
            first.text = combinedTitle;
            return [first];
          }
        }

        return this.comparePropertyData;
      }),
    };
  },
};
</script>
