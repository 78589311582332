<template>
    <td>
        <LabourForecast :eventId="eventId" :labourForecastUrl="labourForecastUrl"></LabourForecast>
    </td>
</template>

<script>
import LabourForecast from "../labourForecast/labour-forecast.vue";
export default {
    name: "cellLabourForecast",
    props: {
        comparePropertyData: Object,
        rubrik: String,
        edunumber: Number,

    },
    components: {
        LabourForecast
    },
    data() {
        return {
            eventId: this.comparePropertyData.eventId, // vi behöver bara första
            labourForecastUrl: this.comparePropertyData.labourForecastUrl
        };
    },
}
</script>
