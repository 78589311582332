<template>
  <template v-if="textData.length > 1">
    <td>
      <ul class="list-unstyled">
        <li v-for="(text, index) in textData">
          <span class="pre-event-label">{{ preEventLabel(index) }}</span
          ><br />
          <span v-if="text !== undefined && text.length > 0">{{
            text
          }}</span>
          <span v-else aria-label="Data saknas">{{ globalText.NA }}</span>
        </li>
      </ul>
    </td>
  </template>
  <td v-else-if="textData.length === 1">
    <span v-if=" textData[0]?.length ?? false">{{
      textData[0]
    }}</span>
    <span v-else aria-label="Data saknas">{{ globalText.NA }}</span>
  </td>
</template>
<script>
import { computed } from "vue";
import { eduCompData } from "./compareData";
export default {
  name: "CellText",
  props: {
    comparePropertyData: Array, // får bara vara Array<string>
    rubrik: String,
  },
  setup() {
    const { eduCompareData } = eduCompData();
    return { globalText: eduCompareData.text };
  },
  methods: {
    preEventLabel(index) {
      return this.globalText.EventLabel + " " + (index + 1);
    },
    writeText(textObject) {
      if (textObject == undefined || textObject.length < 1) {
        return this.globalText.NA;
      }
      return textObject;
    },
  },
  data() {
    return {
      textData: computed(() => this.comparePropertyData),
    };
  },
};
</script>
