<template>
    <div class="filter-button-modal" @click.self="closeModal()">
        <p>{{ infoText }}</p>
        <button class="btn btn-default" @click="closeModal()">{{ buttonText }}</button>
    </div>
</template>

<script>
export default {
    name: "infoPopup",
    props: {
        infoText: String,
        buttonText: String,
    },
    emits: ["closeModal"],
    setup() {
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
    }
}
</script>