<template>
    <div class="admission-stat-info">
        <div v-html="providedAboutTable" class="table-info-text"></div>
        <UnderLineAccordion v-if="isOpen" :heading="heading" :text="providedMoreAboutAdmissionStatistics"
            :isHtml="true">
        </UnderLineAccordion>
    </div>
</template>

<script>
import UnderLineAccordion from "../components/underline-accordion.vue";
export default {
    name: "AdmissionStatsInfo",
    components: {
        UnderLineAccordion,
    },
    props: {
        providedAboutTable: String,
        isOpen: Boolean,
        heading: String,
        providedMoreAboutAdmissionStatistics: String
    }
};

</script>
