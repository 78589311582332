<template>
  <div id="filters">
    <div id="desktopMenu">
      <div class="topfilteritems hidden-xs">
        <TopFilter v-if="filterKP" :filters="filterKP" :compareFilters="filterKPList"
          @filterselected="filterselected" />
        <TopFilter v-if="filterDS" :filters="filterDS" :compareFilters="filterDSList"
          @filterselected="filterselected" />
      </div>
      <ul v-if="!isMobile" v-clickoutside="clickedOutsideFilter">
        <li v-for="filter in filterList" :key="filter.id">
          <FilterEl @filterElement="filterElement" :title="filter.title" :m="filter.value" :query="query"
            :extraFilter="extraFilter" :index="filter.id" :isMobile="filter.mobile" :activeButtonId="activeButtonId"
            :filterList="filterElList" :setActiveSubMenuIndex="subListIndex" :sublists="sublists"
            @filterselected="filterselected" @updateSubFilter="updateSubFilter">
          </FilterEl>
        </li>
      </ul>
    </div>
    <div v-if="isMobile">
      <MobileFilterMenu :filterListMobile="filterListMobile" :filterElLists="filterElLists" :isTablet="isTablet"
        :extraFilter="extraFilter" @setQuery="setQuery" @filterElement="filterElement" @filterselected="filterselected"
        :key="mobileFilterKey">
      </MobileFilterMenu>
    </div>
  </div>
  <InformationPopup />
</template>

<script>
import api from "./plugins/searchcompareservice";
import TopFilter from "./components/topfilter.vue";
import FilterEl from "./components/filterelement.vue";
import MobileFilterMenu from "./components/mobilefiltermenu.vue";
import InformationPopup from "./components/informationpopup.vue"
import { FilterListValue } from "./constants"


export default {
  name: "searchcomparemenu",
  components: {
    TopFilter,
    FilterEl,
    MobileFilterMenu,
    InformationPopup

  },
  emits: ["getresult"],
  data() {
    return {

      filterKP: undefined,
      filterDS: undefined,
      filterElList: undefined,
      filterElLists: [],
      isLoading: false,
      query: "",
      responseGetData: undefined,
      response: "",
      queryPost: "",
      isMobile: false,
      activeButtonId: null,
      activeSubMenuValue: null,
      loadingFilter: undefined,
      isTablet: false,
      sublists: [],
      subListIndex: 0,
      extraFilter: {},
      mobileFilterKey: 0,
      filterKPList: [
        { Title: "Kurs", Value: "k", disabled: "true", ShowAll: '', Href: '', index: 0 },
        { Title: "Program", Value: "p", disabled: "true", ShowAll: '', Href: '', index: 1 },
      ],
      filterDSList: [
        { Title: "På plats", Value: "p", disabled: "true", ShowAll: '', Href: '', index: 0 },
        { Title: "Distans", Value: "d", disabled: "true", ShowAll: '', Href: '', FilterItemInfo: 'Alla utbildningar som hålls på distans. I en del distansutbildningar ingår obligatoriska träffar. Kontrollera vad som gäller i universitetets eller högskolans beskrivning av utbildningen.', index: 1 },
        { Title: "Distans utan träffar", Value: "u", disabled: "true", ShowAll: '', Href: '', FilterItemInfo: 'Utbildningar som hålls på distans helt utan träffar. Obs! Vissa utbildningar på distans utan obligatoriska träffar hittar du under filtret "Distans". ', index: 2 },
      ],
      filterList: {
        0: { title: "Ämne", value: FilterListValue.AMNE, id: 1 },
        1: { title: "Högskola", value: FilterListValue.HOGSKOLA, id: 2 },
        2: { title: "Studieort", value: FilterListValue.STUDIEORT, id: 3 },
        3: { title: "Examen", value: FilterListValue.EXAMEN, id: 4 },
        4: { title: "Utbildningsnivå", value: FilterListValue.NIVA, id: 5 },
        5: { title: "Termin", value: FilterListValue.TERMIN, id: 6 },
        6: { title: "Studietakt", value: FilterListValue.STUDIETAKT, id: 7 },
        7: { title: "Utbildningsspråk", value: FilterListValue.SPRAK, id: 8 },
      },
      filterListMobile: {
        0: { title: "Ämne", value: FilterListValue.AMNE, id: 1 },
        1: { title: "Studieform", value: FilterListValue.STUDIEFORM, id: 10 },
        2: { title: "Examen", value: FilterListValue.EXAMEN, id: 4 },
        3: { title: "Kurs och Program", value: FilterListValue.KURSPROGRAM, id: 9 },
        4: { title: "Högskola", value: FilterListValue.HOGSKOLA, id: 2 },
        5: { title: "Studieort", value: FilterListValue.STUDIEORT, id: 3 },
        6: { title: "Termin", value: FilterListValue.TERMIN, id: 6 },
        7: { title: "Studietakt", value: FilterListValue.STUDIETAKT, id: 7 },
        8: { title: "Utbildningsnivå", value: FilterListValue.NIVA, id: 5 },
        9: { title: "Utbildningsspråk", value: FilterListValue.SPRAK, id: 8 },
      },
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", () => { this.checkIfMobile(), this.setDataToFilter() });
  },
  created() {
    let params = new URLSearchParams(window.location.search);
    if (params.get("m") !== null) {
      this.activeButtonId = parseInt(params.get("m"));
    }
    if (params.get("c") !== null) {
      this.activeSubMenuValue = params.get("c");
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.checkIfMobile);
  },

  methods: {

    reRenderMobileMenu() {
      this.mobileFilterKey += 1;
    },
    setData(queryPost) {
      this.queryPost = queryPost;
      this.query = this.queryPost;
      this.getData();
    },
    async getData(cb) {
      this.isLoading = true;
      await api
        .getData(this.query, this.isMobile)
        .then((result) => {
          if (result && result.status === 200) {
            this.responseGetData = result.data ? result.data : [];
            this.setDataToFilter();
          }
          if (cb) {
            cb();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.error('error while getting data', err);
          this.responseGetData = undefined;
          this.isLoading = false;
        });
    },
    async postData(cb) {
      await this.getData(() => {
        this.$emit("getresult", this.queryPost);
        if (cb) {
          cb();
        }
      });
    },
    setDataToFilter() {
      this.filterElList = undefined;
      this.filterElLists = [];
      var arr = this.responseGetData;

      var priorKnowledgeFilter = arr.filter(x => x.Selectedfilter == 10);

      if (priorKnowledgeFilter && priorKnowledgeFilter.length) {
        //move prior knowledgelevel filter into extraFilter if it exists and remove from the original list
        this.extraFilter = priorKnowledgeFilter[0];
        arr = arr.filter(x => x.Selectedfilter != 10);
      } else {
        //make sure we clear the extrafilter unless it is used
        this.extraFilter = {};
      }

      arr.forEach((element) => {
        const selectedFilter = element.Selectedfilter;
        this.sortFilterObjects(selectedFilter, element.SelectedFilterResult);

        if (selectedFilter === FilterListValue.KURSPROGRAM && !this.isMobile) {
          this.filterKP = element;
          this.filterKP.title = "Kurs och Program";
          this.filterKP.SelectedFilterInfo = element.SelectedFilterInfo;
        } else if (selectedFilter === FilterListValue.STUDIEFORM && !this.isMobile) {
          this.filterDS = element;
          this.filterDS.title = "Studieform";
          this.filterDS.SelectedFilterInfo = element.SelectedFilterInfo;
        } else {
          if (selectedFilter === FilterListValue.AMNE && !this.isMobile) {
            if (this.activeSubMenuValue) {
              this.subListIndex = element.SelectedFilterResult.findIndex(item => item.Value === this.activeSubMenuValue);
            }
            this.filterElList = element;
            this.sublists = this.populateLists(element.SelectedFilterResult[this.subListIndex].SubFilterItems);
          } else if (this.isMobile) {
            if (this.activeSubMenuValue) {
              this.subListIndex = element.SelectedFilterResult.findIndex(item => item.Value === this.activeSubMenuValue);
            }
            this.filterElLists.push(element);
          } else {
            this.filterElList = this.populateLists(element.SelectedFilterResult);
            this.filterElList.title = element.SelectedFilterInfo;
          }
        }
      });
      if (this.isMobile && arr.length == 0) {
        this.reRenderMobileMenu(); //need to do this to reset the mobile filter menu
      }


    },
    clickedOutsideFilter() {
      if (!this.isMobile) {
        this.activeButtonId = null;

        let params = new URLSearchParams(window.location.search);
        let param = params.get("m");
        let replaceQuery = "";
        if (param !== null) {
          params.delete("m");
          replaceQuery = params.toString();
          this.queryPost = replaceQuery;
          history.pushState({}, null, this.$route.path + "?" + this.queryPost);
          this.query = this.queryPost;
        }
      }
    },
    updateSubFilter(index, data, href, value) {
      this.activeSubMenuValue = value;
      this.queryPost = href;
      this.query = this.queryPost;
      history.pushState({}, null, this.$route.path + "?" + this.queryPost);
      this.getData();
      this.subListIndex = index;
      this.sublists = this.populateLists(data);

    },
    filterselected(data) {
      this.queryPost = data;
      history.pushState({}, null, this.$route.path + "?" + this.queryPost);
      this.query = this.queryPost;
      this.postData();
    },
    async filterElement(data) {
      //update state
      let params = new URLSearchParams(window.location.search);
      let param = params.get("m");
      let replaceQuery = "";
      if (param !== null && param == data) {
        params.delete("m");
        params.delete("mobile");
        replaceQuery = params.toString();
      } else if (param !== null && param != data) {
        params.set("m", data);
        params.delete("mobile");
        replaceQuery = params.toString();
      } else {
        params.delete("mobile");
        replaceQuery = params.toString() + "&m=" + data;
      }
      this.queryPost = replaceQuery;
      history.pushState({}, null, this.$route.path + "?" + this.queryPost);
      this.query = this.queryPost;

      //do new search
      await this.postData(() => {
        this.activeButtonId = data === this.activeButtonId ? null : data;
      });
    },
    setQuery(query) {
      this.query = query;
    },

    routeChangeSearch() {
      this.query = Object.keys(this.$route.query)
        .map((key) => {
          return (
            encodeURIComponent(key) +
            "=" +
            encodeURIComponent(this.$route.query[key])
          );
        })
        .join("&");
      this.getData();
    },
    checkIfMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      } else if (window.innerWidth <= 992) {
        this.isMobile = true;
      }
      else {
        this.isMobile = false;
      }
    },
    splitToChunks(array, parts) {
      let result = [];
      let ceil = Math.ceil(array.length / parts);
      for (var i = 0; i < array.length; i += ceil) {
        result.push(array.slice(i, i + ceil));
      }
      return result;
    },
    sortStudietakt(objects) {
      objects.sort((a, b) => a.Value - b.Value);
    },

    sortStudieform(objects) {
      objects.sort(function (a, b) {
        var sortOrder = ['p', 'd', 'u'];

        if (sortOrder.indexOf(a.Value.substr(0, 1)) < sortOrder.indexOf(b.Value.substr(0, 1))) {
          return -1;
        } else if (sortOrder.indexOf(a.Value.substr(0, 1)) > sortOrder.indexOf(b.Value.substr(0, 1))) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    sortUtbildningsniva(objects) {
      objects.sort(function (a, b) {
        var sortOrder = ['f', 'g', 'a'];

        if (sortOrder.indexOf(a.Value) < sortOrder.indexOf(b.Value)) {
          return -1;
        } else if (sortOrder.indexOf(a.Value) > sortOrder.indexOf(b.Value)) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    sortTermin(objects) {
      objects.sort(function (a, b) {
        //First sort by year  
        if (a.Value.substr(1) > b.Value.substr(1)) {
          return 1;
        } else if (a.Value.substr(1) < b.Value.substr(1)) {
          return -1;
        }

        //Then sort by term
        var sortOrder = ['V', 'S', 'H'];

        if (sortOrder.indexOf(a.Value.substr(0, 1)) < sortOrder.indexOf(b.Value.substr(0, 1))) {
          return -1;
        } else if (sortOrder.indexOf(a.Value.substr(0, 1)) > sortOrder.indexOf(b.Value.substr(0, 1))) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    sortFilterObjects(filterValue, filterObjects) {
      switch (filterValue) {
        case FilterListValue.STUDIETAKT:
          this.sortStudietakt(filterObjects);
          break;
        case FilterListValue.TERMIN:
          this.sortTermin(filterObjects);
          break;
        case FilterListValue.STUDIEFORM:
          this.sortStudieform(filterObjects);
          break;
        case FilterListValue.NIVA:
          this.sortUtbildningsniva(filterObjects);
          break;
        default:
          break;
      }
    },
    populateLists(objects) {
      if (objects !== null) {
        let res = [];

        let list1 = [];
        let list2 = [];
        let list3 = [];
        let list4 = [];

        let objectLen = Object.keys(objects).length;

        if (objectLen < 9) {
          res = this.splitToChunks(objects, 1)
          list1 = res[0];
        } else if (objectLen >= 9 && objectLen < 13) {
          res = this.splitToChunks(objects, 2)
          list1 = res[0];
          list2 = res[1];
        } else if (objectLen >= 13 && (objectLen < 20 || this.activeButtonId == 1)) {
          res = this.splitToChunks(objects, 3)
          list1 = res[0];
          list2 = res[1];
          list3 = res[2];
        } else {
          res = this.splitToChunks(objects, 4)
          list1 = res[0];
          list2 = res[1];
          list3 = res[2];
          list4 = res[3];
        }
        this.lists = [list1, list2, list3, list4];
        return this.lists;
      }
    },
  },
  watch: {
    $route: "routeChangeSearch",
  },
};
</script>