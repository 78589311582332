<template>
    <searchAutocomplete @search="doSearch" :search-label="searchLabel" :search-button-text="searchButtonText" />
    <div v-if="showFilter" id="edu-filter-menu" class="row">
        <div class="col-xs-12">
            <h2>Filtrera sökresultat</h2>
            <searchCompareMenu ref="filter" @getresult="getresult"></searchCompareMenu>
        </div>
    </div>

    <div v-if="showResult" id="edu-results-page" class="row">
        <searchCompareResult ref="result" @setdata="setdata" @sort-order-parameter="sortOrderParameter"
            :grandparentState="grandparentState" :infoBubbleText="infoBubbleText">

        </searchCompareResult>

    </div>
</template>

<script>

import searchCompareMenu from "./searchcomparemenu.vue";
import searchCompareResult from "./searchcompareresult.vue";
import searchAutocomplete from "./components/searchAutocomplete.vue";


export default {
    name: "searchcompare",
    components: {
        searchAutocomplete,
        searchCompareMenu,
        searchCompareResult,

    },
    props: {
        aboutTable: String,
        tableSymbols: String,
        whatSelectionGroups: String,
        aboutSelectionGroups: String,
        moreAboutAdmissionStatistics: String,
        searchLabel: String,
        searchButtonText: String,
        infoBubbleText: String
    },
    provide() {
        return {
            'providedAboutTable': this.aboutTable,
            'providedTableSymbols': this.tableSymbols,
            'providedWhatSelectionGroups': this.whatSelectionGroups,
            'providedAboutSelectionGroups': this.aboutSelectionGroups,
            'providedMoreAboutAdmissionStatistics': this.moreAboutAdmissionStatistics
        };
    },
    created() {
        let params = new URLSearchParams(window.location.search);
        if (params.size == 0 || params.has("cv") && params.get("cv") !== "99" || ((params.has("asp") || params.has("lf")) && this.checkIfMobile())) {
            this.showFilter = false;
            this.showResult = false;
        } else {
            this.showFilter = true;
            this.showResult = true;
        }
    },
    data() {
        return {
            hideFilter: Boolean,
            hideResult: Boolean,
            grandparentState: 'relevance',
            searchtext: ''
        };
    },
    methods: {
        //Todo: byt detta då det inte är deklarativt. 
        // <searchCompareResult/> ska "lyssna" på förändring av filters
        getresult(querypost) {
            this.$refs.result.getResult(querypost, this.grandparentState);
        },

        setdata(querypost) {
            this.$refs.filter.setData(querypost);
        },
        doSearch(searchText) {
            let params = new URLSearchParams(window.location.search);
            if (params.has("q")) {
                params.delete("q");
            }

            //Nollställ paginering
            if (params.has("p")) {
                params.delete("p");
            }

            params.append("q", searchText);
            var queryPost = params.toString();

            // finns filter så uppdatera url:en och hämta resultatet.
            if (this.showFilter) {
                history.pushState({}, null, this.$refs.filter.$route.path + "?" + queryPost);
                this.getresult(queryPost);
            }
            else {
                // vi är på kartsidan
                window.location.href = window.location.href + "?" + queryPost;
            }


        },

        checkIfMobile() {
            let isMobile = false;
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                isMobile = true;
            } else if (window.innerWidth <= 992) {
                isMobile = true;
            }
            else {
                isMobile = false;
            }
            return isMobile;
        },
        sortOrderParameter(newState) {
            this.grandparentState = newState;
        }
    }
};
</script>
