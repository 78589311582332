<template>
    <div class="multiselect-container" v-clickoutside="closeDropDown">
        <button tabindex="0" :aria-expanded="isOpen" @click="toggleDropDown()" aria-controls="multiselect-dropdown"
            :class="`multiselect__toggle ${plausibleTrackingClass}`">
            <span v-if="this.modelValue.length === 0">Välj urvalsgrupp</span>
            <span v-else-if="this.modelValue.length === 1">{{ this.modelValue[0] }}</span>
            <span v-else>{{ this.modelValue.length }} valda </span>
            <SvgIcon IconName="triangel-pil-ned"></SvgIcon>
        </button>
        <div :class="`multiselect__dropdown ${isOpen ? 'open' : ''}`">
            <ul>
                <li v-for="item in list" class="option" role="option">
                    <label tabindex="-1"
                        :class="`container-choice ${isSelected(item.Code) ? 'active' : ''} ${this.modelValue.length >= maxActiveItems && this.modelValue.indexOf(item.Code) === -1 ? 'disabled' : ''}`">
                        <input :checked="isSelected(item.Code)" @change="handleCheckboxChange(item.Code)"
                            type="checkbox" :value="item.Code"
                            :disabled="this.modelValue.length >= maxActiveItems && this.modelValue.indexOf(item.Code) === -1">
                        <span class="txt">{{ item.Code }} - {{ item.Explanation }}</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import SvgIcon from "../misc-components/svgicon.vue";
export default {
    name: "MultiSelectDropDown",
    components: {
        SvgIcon,
    },
    props: {
        maxActiveItems: Number,
        propsList: Array,
        plausibleTrackingClass: String,
        modelValue: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isOpen: false,
            list: [],
        }
    },

    mounted() {
        this.list = this.propsList;
    },
    methods: {
        toggleDropDown() {
            this.isOpen = !this.isOpen;
        },
        closeDropDown() {
            this.isOpen = false;
        },
        handleCheckboxChange(code) {
            const newValue = this.isSelected(code)
                ? this.modelValue.filter(item => item !== code)
                : [...this.modelValue, code];
            this.$emit('update:modelValue', newValue);
        },
        isSelected(code) {
            return this.modelValue.includes(code);
        }
    },
};
</script>
