<template>
    <div v-if="searchResult.length !== 0" id="edu-results-list" class="col-xs-12 no-js">
        <div class="row">
            <div class="col-sm-12 result-header">
                <h2 id="resultmetrics">Dina val: {{ Number(searchResult.TotalHits).toLocaleString() }} träffar</h2>
                <div
                    :class="`filtered-sortable-container ${searchResult.SearchTerm || (searchResult.SearchFilters && searchResult.SearchFilters.length > 0) ? 'full' : ''}`">
                    <div class="active-filters-wrapper"
                        v-if="searchResult.SearchTerm || (searchResult.SearchFilters && searchResult.SearchFilters.length > 0)">
                        <a v-if="searchResult.SearchTerm" role="button" class="active-filters-wrapper-filter"
                            @click.prevent="removeFilter(searchResult.RemoveSearchTermUrl)"
                            :href="searchResult.RemoveSearchTermUrl" :aria-label="`list-${searchResult.SearchTerm}`">
                            <SvgIcon IconName="kryss"></SvgIcon>
                            <span>Sökterm: {{ searchResult.SearchTerm }}</span>
                        </a>

                        <a v-for="filter in searchResult.SearchFilters" @click.prevent="removeFilter(filter.RemoveHref)"
                            class="active-filters-wrapper-filter" role="button"
                            :aria-label="`X Ta bort filtret ${filter.Name}`" :href="filter.RemoveHref">
                            <SvgIcon IconName="kryss"></SvgIcon>
                            <span> {{ filter.FilterType + ": " + filter.Name }} </span>
                        </a>

                        <a v-if="searchResult.SearchTerm || (searchResult.SearchFilters && searchResult.SearchFilters.length > 0)"
                            @click.prevent="removeFilter(searchResult.RemoveAllFiltersUrl)" role="button"
                            aria-label="X Rensa alla" class="active-filters-wrapper-filter clearall"
                            :href="searchResult.RemoveAllFiltersUrl">
                            <span>Rensa alla</span>
                        </a>
                    </div>
                    <div class="custom-dropdown sorting-filter">
                        <span class="sorting-text">Sortera på</span>
                        <DropDown :options="sortOptions" :sorteringsOrdning="sorteringsOrdning"
                            @select-option="sortOrder">
                        </DropDown>
                    </div>
                </div>
            </div>
        </div>

        <div class="row higher">
            <div class="col-sm-12 col-md-8 col-md-offset-4">

                <div :class="{ 'info-bubble': true, 'close': hideInfoBubble, 'done': infoBubbledone }">
                    <div class="bubble-text">
                        <SvgIcon IconName="information" />
                        <p v-html="infoBubbleText"></p>
                    </div>
                    <div class="bubble-arrow"></div>
                </div>
            </div>
        </div>

        <div class="row lower">
            <div id="results-area" class="col-xs-12">
                <ul v-if="searchResult.SearchHits2 != null && searchResult.SearchHits2.length > 0">
                    <EducationCard v-for="(result) in searchResult.SearchHits2" :key="result.Id" v-bind:id="result.Id"
                        :resultInfo="result" :IsVisibleForAdmin="searchResult.IsVisibleForAdmin"
                        :noOfItemsInBasket="searchResult.EducationsCompareBasket.length" @compareHit=compareHit
                        @displayStats=statsDisplayed :nrOfResultHits="searchResult.TotalHits">
                    </EducationCard>
                </ul>
                <div v-else class="noResults">
                    <h2 class="noResultsHeader">{{ searchResult.NoHitsHeader }}</h2>
                    <div class="noResultsText">
                        {{ searchResult.NoHitsText }}
                        <a :href="searchResult.NoHitsUrl">{{ searchResult.NoHitsLinkText }}</a>
                    </div>
                </div>
            </div>
            <div class="result-paging col-xs-12"
                v-if="searchResult.SearchHits2 != null && searchResult.SearchHits2.length > 0">
                <ResultPaging :pagedList="searchResult.PagedList" :sorteringsOrdning="sorteringsOrdning"></ResultPaging>
            </div>
        </div>
        <CompareBasket v-if="searchResult.EducationsCompareBasket" @removeHit="removeHit"
            :searchResult="searchResult" />
    </div>
    <div v-else>
        <div style="text-align:center"><img src="/assets/img/uhr-spinner.gif" style="margin: 100px auto; width:auto"
                alt="Loading..." /></div>
    </div>
</template>

<script>
import api from "./plugins/searchcompareservice";
import EducationCard from "./components/education-card.vue";
import ResultPaging from "./components/result-paging.vue";
import CompareBasket from "./components/comparebasket.vue";
import SvgIcon from "./misc-components/svgicon.vue";
import DropDown from "./components/dropdown.vue";
import { sortOptions } from "./constants";

export default {
    name: "searchcompareresult",
    emits: ['setdata'],
    components: {
        SvgIcon,
        EducationCard,
        CompareBasket,
        ResultPaging,
        DropDown
    },
    props: {
        grandparentState: String,
        infoBubbleText: String
    },
    data() {
        return {
            queryPost: "",
            query: "",
            searchResult: [],
            sortOptions: sortOptions,
            sorteringsOrdning: "",
            test: "",
            hideInfoBubble: sessionStorage?.getItem("info-bubble-toggle") === "true",
            infoBubbledone: sessionStorage?.getItem("info-bubble-toggle") === "true"
        }
    },
    watch: {
        $route(newRoute, oldRoute) {
            if (newRoute.fullPath !== oldRoute.fullPath) {
                let params = new URLSearchParams(window.location.search);
                if (params !== null && params.get("so")) {
                    this.sorteringsOrdning = params.get("so");
                    var replaceQuery = "";
                    params.delete("so");
                    replaceQuery = params.toString();
                    history.pushState({}, null, this.$route.path + "?" + replaceQuery);
                }
            }
            this.routeChangeSearch()
        },
    },
    mounted() {
        this.sorteringsOrdning = this.grandparentState;
    },
    methods: {
        getResult(queryPost, sorteringsOrdning) {
            api
                .postData(queryPost, sorteringsOrdning)
                .then((result) => {
                    if (result && result.status === 200) {
                        this.searchResult = result.data ? result.data : [];
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        routeChangeSearch() {
            this.test = this.$route.query;
            this.query = Object.keys(this.$route.query)
                .map((key) => {
                    return (
                        encodeURIComponent(key) +
                        "=" +
                        encodeURIComponent(this.$route.query[key])
                    );
                })
                .join("&");
            this.getResult(this.query, this.sorteringsOrdning);
        },
        removeFilter(href) {
            this.clearInput('SearchBox');
            this.queryPost = href;
            history.pushState({}, null, this.$route.path + "?" + this.queryPost);
            this.getResult(this.queryPost, this.sorteringsOrdning);
            this.$emit("setdata", this.queryPost);
        },
        clearInput(field) {
            const inputField = document.getElementById(field);
            if (inputField) {
                inputField.value = '';
            }
        },
        statsDisplayed(visible) {
            this.hideInfoBubble = true;
            sessionStorage.setItem("info-bubble-toggle", true);
            setTimeout(() => {
                this.infoBubbledone = true;
            }, 500);
        },
        compareHit(resultInfo) {
            const update = () => {
                history.pushState({}, null, this.$route.path + "?" + this.queryPost);
                this.getResult(this.queryPost, this.sorteringsOrdning);
            };

            if (resultInfo.AddedToCompareBasket) {
                this.queryPost = resultInfo.RemoveItemFromBasketUrl;
                update();
            } else {
                if (this.searchResult.EducationsCompareBasket.length < 5) {
                    this.queryPost = resultInfo.AddItemToBasketUrl;
                    update();
                }
            }
        },
        removeHit(removeItemFromBasketUr) {
            this.queryPost = removeItemFromBasketUr;
            history.pushState({}, null, this.$route.path + "?" + this.queryPost);
            this.getResult(this.queryPost, this.sorteringsOrdning);
            this.$emit("setdata", this.queryPost);
        },
        sortOrder(selectedOption) {
            this.sorteringsOrdning = selectedOption.parameter;
        }
    },
};
</script>