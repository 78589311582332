import svgicon from "./atoms/svgicon";

//When called (preferably when DOM' is done) will append icon to selected items.
export default function iconappender() {
	// Finds external a-tags with http or https links not containing current hostname.
	var $externalLinks = $("a[href*='://']")
		.not("[href*='" + window.location.hostname + "']")
		.not(':has("img"), .cta-button');
	appendIcon($externalLinks, "icon-extern-lank", $.i18n("openInNewWindow"));

	// Finds local a-tags in selected areas, without images and not linking to pdf-documents.
	var findIn =
		"#mainBody, .blockFrame.text, #areainfo, .aboutUhrMenu, .guide_step_container, .relatedLinks, .information_tab_content, #educationInformationPage .linkGroup, #guidePage .linkGroup";
	var $arrowlinks = $(findIn)
		.find("a[href^='/']")
		.not(':has("img"), a[href$=".pdf"]');
	appendIcon($arrowlinks, "icon-pil-hoger-ny");

	var $bestbetslinkinternal = $(".ess-bestBet")
		.find("a[href*='://']")
		.filter("[href*='" + window.location.hostname + "']")
		.not(':has("img"), a[href$=".pdf"]');
	appendIcon($bestbetslinkinternal, "icon-pil-hoger-ny");

	var $pdflinks = $(findIn).find('a[href$=".pdf"]');
	appendIcon($pdflinks, "icon-dokument-pdf");
}

function appendIcon($links, iconName, screenReaderText) {
	if (!$links && $links.length < 1) {
		return;
	}
	var forcedExcludes = `.preventAutomaticIcon`; //always exclude items with this class.
	var $filtered = $links.not(forcedExcludes);

	$filtered.append(svgicon(iconName));
	if (screenReaderText) $filtered.append(getScreenReaderSpan(screenReaderText));
}

function getScreenReaderSpan(text) {
	return `<span class="sr-only">, ${text}</span>`;
}
