<template>
    <button
      :aria-label="`Visa information om ${filterGroup} ${filterTitle}`"
      v-if="filterItemInfo"
      :class="['modal-button info-wrapper', isInFocus ? 'active' : '']"
      @click="openPopup(filterItemInfo, $event)"
      @keypress.enter="openPopup(filterItemInfo, $event)"
    >
      <SvgIcon IconName="information"></SvgIcon>
    </button>
  </template>
  
<script>
import SvgIcon from "../misc-components/svgicon.vue";
import { getCurrentInstance } from "vue";
export default {
    name: "informationicon",
    components: {
        SvgIcon
    },
    props: {
        filterItemInfo: String,
        filterTitle: String,
        filterGroup: String
    },
    data() {
        return {
            isInFocus: false,
            informationPopup: getCurrentInstance().appContext.config.globalProperties.$informationPopup,
        }
    },
    methods: {
        async openPopup(message, event) {    
            event.preventDefault();      
             if (this.isInFocus) {
                this.$informationPopup.hidePopup();
                this.isInFocus = false;              
            } else {
                this.$informationPopup.showPopup(message, event)
                await this.$nextTick();
                this.isInFocus = true;               
            }            
        },
    },
    watch: {
        'informationPopup.isPopupVisible': async function (currentState, prevState) {
            if (currentState !== prevState && currentState == false) {
                this.isInFocus = false;
            }        
        },
        'informationPopup.updateState': async function (currentState, prevState) {
            if (currentState !== prevState) {
                this.isInFocus = false;
            }        
        }
    }
}
</script>