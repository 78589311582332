export interface CompareItem {
	data: Education;
	name: string;
	id: string;
	visible: boolean;
	urlToStatistics: string;
	urlToLabourForecast: string;
}
export interface Education {
	Id: string;
	ExpandId: string;
	Title: string;
	Hp: string;
	Time: string;
	Language: string;
	Distance: string;
	PaceOfStudy: string[];
	Provider: string;
	Description?: string;
	Specialization: string;
	Categories: string;
	Events: Event[];
	AdmissionStatisticsUrl: string;
	HasDifferentPace: boolean;
	PaceOfStudyParsed: string;
	TimeParsed: string;
	Identifier: string;
	HasDifferentLanguage: boolean;
	Url: string;
	EventString: any;
	AddedToCompareBasket: boolean;
	AddItemToBasketUrl: any;
	RemoveItemFromBasketUrl: any;
	AddOrRemoveText: any;
	ShowHideSelected: any;
	LabourForcastUrl: any;
}

export interface Event {
	Id: string;
	Title: string;
	Location: string;
	EducationLevel: string;
	Start: string;
	Degree?: string;
	Eligibility: string;
	LastApplication: any;
	ApplicationCode: string;
	ApplicationUrl: string;
	ApplicationUrlType: number;
	Language: string;
	PaceOfStudy: string;
	ParsedPaceOfStudy: string;
	Distance: string;
	SpecializationCategories: SpecializationCategory[];
	Url: string;
	ApplicationOpen: boolean;
	Configuration: string;
}

export interface SpecializationCategory {
	Name: string;
}

export interface FilterButton {
	filter: Filter;
}

export interface Filter {
	IsSelectedFilter: boolean;
	disabled: boolean;
	Href: string;
	Title: string;
	ShowAll: boolean;
	FilterItemInfo: string;
}

export class EducationStart {
	ar: number = 0;
	termin: string = "";
	period: number = 0;
	isValid: boolean = false;
	constructor(textstring: string) {
		let start = textstring.split(" ");
		if (this.Validate(start)) {
			this.ar = parseInt(start[1]);
			this.termin = start[0];
			this.isValid = true;
			if (start.length == 4) {
				this.period = parseInt(start[3]);
			} else {
				// om period inte finns så är det 1
				this.period = 1;
			}
		} else {
			this.isValid = false;
		}
	}

	// ska ha minst VT 2034 (termin och år)
	Validate = (text: Array<string>): boolean => {
		if (text[0] == "VT" || text[0] == "HT") {
			if (parseInt(text[1]) > 2000) {
				return true;
			}
		}
		return false;
	};

	compare = (other: EducationStart) => {
		// kolla validitet
		if (!this.isValid && !other.isValid) return 0;
		if (!this.isValid) return 1;
		if (!other.isValid) return -1;

		// kolla år
		if (this.ar == other.ar) {
			//kolla termin
			if (this.termin == other.termin) {
				//kolla period
				return this.period - other.period; // 1-2 => att this kommer före 2
			}
			if (this.termin == "VT") return -1;
			if (other.termin == "VT") return 1;
		}
		return this.ar - other.ar; // neg => this kommer föer
	};
}

export interface CellLinkDataGatherer {
	linkUrl: (ci: Education) => string;
	linkText: (ci: Education) => string | string;
	iconName?: string;
}

export class CellLinkModel {
	url: string;
	text: string;
	iconName: string;

	haveIcon: boolean = false;

	constructor(url: string, text: string, iconName: string | null | undefined) {
		this.url = url;
		this.text = text;
		this.iconName = iconName ?? "";

		if (iconName == null || iconName == "" || iconName == undefined) {
			this.haveIcon = false;
		}
	}

	compare = (other: CellLinkModel) => {
		if (this.url == other.url) {
			return 0;
		}
		return -1;
	};
}
