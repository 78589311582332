<template>
  <tr class="comprow">
    <th scope="row">{{ rowHeader }}</th>
    <component
      :is="cellTyp"
      v-for="(cellData, index) in compareRowData"
      :combine="combine"
      :comparePropertyData="cellData ?? ''"
      :rubrik="rowHeader"
      :eduNumber="index"
    >
    </component>
  </tr>
</template>

<script>
import { computed } from "vue";
import { eduCompData } from "./compareData";
import { rowDataUtilities } from "./RowDataUtilities";
import cellText from "./cellText.vue";
import cellHtmlText from "./cellHtmlText.vue";
import cellLink from "./cellLink.vue";
import cellProcent from "./cellProcent.vue";
import CellAntalHp from "./cellAntalHp.vue";
import cellLabourForecast from "./cellLabourForecast.vue";

export default {
  name: "compareRow",
  components: {
    cellText,
    cellHtmlText,
    cellLink, // med och utan icon
    cellProcent,
    CellAntalHp,
    cellLabourForecast,
  },
  props: {
    getPropertyData: Function,
    accomodate: Boolean,
    combine: Boolean,
    rowHeader: String,
    cellTyp: String,
  },
  mounted() {},
  setup(props) {
    const { accommodateArray } = rowDataUtilities();
    const { compareItems } = eduCompData();

    const compareRowData = computed(() => {
      let dataArr = compareItems.value.map(props.getPropertyData);
      if(props.accomodate){
        return accommodateArray(dataArr);
      }

      return dataArr;
    });

    return {
      compareRowData,
    };
  },
};
</script>
