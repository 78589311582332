/**
 **********
 * Quicksearch Autocomplete,
 **********
 **/

//import $ from "jquery";

export default function quickSearchinitalizer() {
	$(".autocomplete-suggestions").attr("role", "listbox");

	$("#SearchBox1_QuickSearchBox").autocomplete({
		ajaxSettings: {
			method: "POST",
			contentType: "application/json; charset=UTF-8",
		},
		appendTo: $("#SearchBox1_QuickSearchBox-autocomplete-suggestion-list"),
		triggerSelectOnValidInput: false,
		serviceUrl: "/Search/GetAutoComplete",
		onSelect: function (suggestion) {
			window.location.href =
				suggestion.url +
				"?q=" +
				encodeURIComponent(suggestion.value) +
				"&s=rel_desc";
		},
	});

	$("#SearchBox2_QuickSearchBox").autocomplete({
		ajaxSettings: {
			method: "POST",
			contentType: "application/json; charset=UTF-8",
		},
		appendTo: $("#SearchBox2_QuickSearchBox-autocomplete-suggestion-list"),
		triggerSelectOnValidInput: false,
		serviceUrl: "/Search/GetAutoComplete",
		onSelect: function (suggestion) {
			window.location.href =
				suggestion.url +
				"?q=" +
				encodeURIComponent(suggestion.value) +
				"&s=rel_desc";
		},
	});

	$("#SearchBox1_QuickSearchBox").keydown(function (e) {
		if (e.keyCode === 13) {
			window.location.href =
				suggestion.url +
				"?q=" +
				encodeURIComponent($("#SearchBox1_QuickSearchBox").val()) +
				"&s=rel_desc";
			return false;
		}
	});
	$("#SearchBox2_QuickSearchBox").keydown(function (e) {
		if (e.keyCode === 13) {
			window.location.href =
				suggestion.url +
				"?q=" +
				encodeURIComponent($("#SearchBox2_QuickSearchBox").val()) +
				"&s=rel_desc";
			return false;
		}
	});
	$(".QuickSearchButton").click(function () {
		window.location.href =
			suggestion.url +
			"?q=" +
			encodeURIComponent($("#SearchBox1_QuickSearchBox").val()) +
			"&s=rel_desc";
		return false;
	});
}
