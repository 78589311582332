<template>
  <div class="prognosis">
    <div>
      <h3>{{ this.prognosis.Header }}</h3>
    </div>
    <template v-if="this.haveAnyForcast">
      <div class="forecasts">
        <div class="forecast">
          <div class="forecast-title">{{ this.texts.nowHeader }}</div>
          <div class="prognosis-icon" v-if="this.nowPrognosis.havePrognosis">
            <img :src="this.nowPrognosis.imgSrc" :alt="this.nowPrognosis.text" />
          </div>

          <div class="forecasttext" v-html="this.nowPrognosis.text"></div>
        </div>
        <div class="forecast">
          <div class="forecast-title">{{ this.texts.fiveYearsHeader }}</div>
          <div class="prognosis-icon" v-if="this.fiveYearsPrognosis.havePrognosis">
            <img :src="this.fiveYearsPrognosis.imgSrc" :alt="this.fiveYearsPrognosis.text" />
          </div>
          <div class="forecasttext" v-html="this.fiveYearsPrognosis.text"></div>
        </div>
      </div>

      <div v-if="this.prognosis.IngressOneYear" class="labourForecastIngress" v-html="this.prognosis.IngressOneYear">
      </div>
      <div v-if="this.prognosis.Ingress" class="labourForecastIngress" v-html="this.prognosis.Ingress"></div>
      <details v-if="this.prognosis.MainText || (!this.hideSalaryStatistics && this.prognosis.SalaryStatistic)"
        class="maintext">
        <summary class="show-more-link">
          <span class="caret"></span>Läs mer om {{ this.prognosis.Header }}
        </summary>
        <div v-html="this.prognosis.MainText"></div>
        <div v-if="!this.hideSalaryStatistics" class="salarystatistic" v-html="this.prognosis.SalaryStatistic"></div>
      </details>
    </template>

    <div v-else class="labourForecastIngress missing" v-html="this.missingPrognosisText"></div>
  </div>
</template>

<script>
export default {
  name: "prognosis",
  props: {
    prognosis: {
      type: Object,
      required: true,
    },
    missingPrognosisText: {
      type: String,
      required: false,
      default: "<p>Prognos saknas.</p>",
    },
    hideSalaryStatistics: Boolean,
  },
  setup(props) {
    function getText(property) {
      if (property) {
        return texts[property];
      } else {
        return "Fel: hittar inte egenskapen";
      }
    }

    const getAltText = (forecastNumber) => {
      if (forecastNumber && forecastNumber > 2 && forecastNumber < 9) {
        return getText("level" + forecastNumber);
      } else {
        return getText("missing");
      }
    };
    const getForecast = (forecast) => {
      let nrStr = parseInt(forecast); // 3.0 -> 3
      if (!forecast || nrStr == 0) {
        return {
          imgSrc: "",
          text: getAltText(nrStr),
          altText: getAltText(nrStr),
          havePrognosis: false,
        };
      }

      let imgSrc = "/Static/images/prognos_level" + nrStr + ".png";

      let altText = getAltText(nrStr);

      return { imgSrc: imgSrc, text: altText, havePrognosis: true };
    };

    const texts = {
      back: "Tillbaka",
      header: "Arbetsmarknaden",
      degreeofestablishmentlink: "Mer information om etableringsgrad",
      nationalaveragetext: "Rikssnitt yrkesexamen",
      noforecasts:
        "Det finns inga arbetsmarknadsprognoser relaterade till den valda utbildningen.",
      level3: "Små möjligheter till arbete",
      level4: "Medelstora möjligheter till arbete",
      level5: "Stora möjligheter till arbete",
      level6: "På fem års sikt förväntas efterfrågan minska jämfört med idag",
      level7: "På fem års sikt förväntas efterfrågan vara oförändrad",
      level8: "På fem års sikt förväntas efterfrågan öka jämfört med idag",
      missing: "Ingen prognos",

      nowHeader: "Nuläge",
      fiveYearsHeader: "Prognos om 5 år",
      levelNotFound: "hittar ingen prognostext",
    };

    let nowImage = getForecast(props.prognosis.ForecastOneYear);
    let fiveYearsImage = getForecast(props.prognosis.Forecast);

    const haveAtleastOne =
      nowImage.havePrognosis || fiveYearsImage.havePrognosis;

    return {
      haveAnyForcast: haveAtleastOne,
      prognosis: props.prognosis,
      hideSalaryStatistics: props.hideSalaryStatistics,
      nowPrognosis: nowImage,
      fiveYearsPrognosis: fiveYearsImage,
      texts: texts,
    };
  },
};
</script>
