<template>
    <div v-if="isPopupVisible" class="popup" v-bind:style="modalPosition" @click="close">
        <div class="popup-inner">
            <p v-html="message"></p>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, } from "vue";

const OffsetPosition = 141;
const PopupWidth = 300;

export default {
    name: "informationpopup",
    mounted() {
        const currentInstance = getCurrentInstance();
        if (currentInstance) {
            currentInstance.appContext.config.globalProperties.$informationPopup = this;
        }
        window.addEventListener('scroll', this.debounce(this.handleScroll, 10));
        window.addEventListener("resize", this.debounce(this.handleResize,10));
        document.addEventListener('click', this.closePopup);
    },
    unmounted() {
        window.removeEventListener('scroll', this.debounce(this.handleScroll, 10));
        window.removeEventListener("resize", this.debounce(this.handleResize,10));
        document.removeEventListener('click', this.closePopup);
    },
    data() {
        this.offsetPosition = OffsetPosition;
        this.popupWidth = PopupWidth;
        return {
            message: '',
            posX: -1,
            posXArrow: 0,
            posY: -1,
            isPopupVisible: false,
            updateState: 0,
            scrollY: 0,
            scrollX: 0,
            startScrollY: 0,
            startWindowWidth: 0
        }
    },
    methods: {
        close() {
          this.hidePopup();
        },
        closePopup(event) {
            if (event.target && event.target.parentElement &&
                event.target.className.baseVal !== 'icon icon-information' &&
                event.target.parentElement.className.baseVal !== 'icon icon-information') {
                this.hidePopup(); // Close popup unless we are trying to open one
            }
        },
        showPopup(message, event) {

            let leftEdge = parseInt(document.getElementById('searchComparePage').getBoundingClientRect().left);
            let rightEdge = parseInt(document.getElementById('searchComparePage').getBoundingClientRect().right);

            let bounds = event.currentTarget.getBoundingClientRect();
            let newPosX = parseInt(bounds.left - this.offsetPosition);

            this.posXArrow = this.offsetPosition;

            if(newPosX < leftEdge){
                this.posXArrow = this.offsetPosition - leftEdge + newPosX;
                newPosX = leftEdge;
            }else if((newPosX + this.popupWidth) > rightEdge ){
                this.posXArrow = this.offsetPosition - rightEdge + newPosX + this.popupWidth;
                newPosX = rightEdge - this.popupWidth;
            }

            let newPosY = parseInt(bounds.top + 28);

            if (this.posX == newPosX && this.posY == newPosY) { // click the same info icon closes the popup (but that should be handled by hidePopup)
                this.hidePopup(); 
            } else {
                if ((this.posX != -1 || this.posY != -1) && (this.posX != newPosX || this.posY != newPosY)) { // click another info icon when the popup is already open
                    this.updateState += 1;
                }
                
                this.startScrollY = parseInt(window.scrollY);
                this.scrollY = this.startScrollY;
                this.startWindowWidth = parseInt(window.innerWidth);
                this.scrollX = this.startWindowWidth;
                this.message = message;
                this.posX = newPosX;
                this.posY = newPosY;
                this.isPopupVisible = true; 
            }
        },
        hidePopup() {
            this.isPopupVisible = false;
            this.posX = this.posY = -1;
            this.startWindowWidth = this.startScrollY = this.scrollX = this.scrollY = this.posXArrow = 0;
            this.message = '';            
        },
        handleScroll(event) {
            if (this.isPopupVisible) {
                this.scrollY = parseInt(window.scrollY);
            }
        },
        handleResize(event) {
            if (this.isPopupVisible) {
                this.scrollX = parseInt(window.innerWidth);
            }
        },
        debounce(fn, wait) {
            let timer;
            return function (...args) {
                if (timer) {
                    clearTimeout(timer); // clear any pre-existing timer
                }
                const context = this; // get the current context
                timer = setTimeout(() => {
                    fn.apply(context, args); // call the function if time expires
                }, wait);
            }
        }

    },
   
    computed: {
        modalPosition: function () {
            let calcY = this.posY - this.scrollY + this.startScrollY;
            let calcX = this.posX - (this.startWindowWidth - this.scrollX)/2;
            let style = 'top: ' + calcY + 'px; left: ' + calcX + 'px; --leftPos: ' + this.posXArrow + 'px;';
            return style;
        }
    }
};
</script>

