<template>
  <div v-if="filters.SelectedFilterResult.length" class="topfilteritem">
    <ul class="filtertopmenu">
      <li :class="`topfilter filter-type-${filters.Selectedfilter}`">
        <fieldset>
          <legend>
            <span class="filterGridHeader">{{ filters.title }}</span>
            <informationIcon :filterItemInfo="filters.SelectedFilterInfo" :filterGroup="filters.title" filterTitle="">
            </informationIcon>
          </legend>
          <div v-for="(filter, index) in filters.SelectedFilterResult" :key="filter.Title" v-bind:id="filter.Value">
            <checkBox :filter="filter" :index="index" :selectedFilter="filters.Selectedfilter" @filterselected="toggle">
            </checkBox>
          </div>
        </fieldset>
      </li>
    </ul>
  </div>
</template>

<script>
import checkBox from "./checkbox.vue";
import informationIcon from "../components/informationicon.vue";
export default {
  name: "topfilter",
  components: {
    checkBox,
    informationIcon
  },
  emits: ['filterselected'],
  props: {
    filters: Object,
    compareFilters: Array,
    isCourseAndDistance: Boolean,
  },
  mounted() {
    this.disabledFilter()
  },
  updated() {
    this.disabledFilter()

  },
  methods: {
    toggle(data) {
      this.$emit("filterselected", data);
    },
    disabledFilter() {
      var filterValues = [];
      this.filters.SelectedFilterResult.forEach(filter => {

        filterValues.push(filter.Value);

      })
      this.compareFilters.forEach(compfilter => {

        if (!filterValues.includes(compfilter.Value)) {
          this.filters.SelectedFilterResult.splice(compfilter.index, 0, compfilter);
        }
      });
    },
  },
};
</script>
